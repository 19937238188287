var util = require('util')

function Query(searchConfig, query) {
    var self = this;
    query = query || util.parseQueryString()
    this.keyword = query.keyword
    this.section = query.section
    this.start = !!query.start ? parseInt(query.start) : 1
    this.scroll = !!query.scroll ? parseInt(query.scroll) : 0
    this.url = searchConfig.url
    this.num = searchConfig.num
    this.loadAll = true

    self.toRequestObj = function () {
        var keyword = ko.unwrap(this.keyword)
        var section = ko.unwrap(this.section)
        var page = ko.unwrap(this.start)
        var pagesize = ko.unwrap(this.num)
        var loadAll = ko.unwrap(this.loadAll)

        if (loadAll) {
          pagesize = page * pagesize;
          page = 1;
        }

        return { keyword: keyword, section: section, page: page, pageSize: pagesize }
    }

    self.toQueryString = function () {
        var query = {
            keyword: ko.unwrap(this.keyword),
            section: ko.unwrap(this.section),
            start: ko.unwrap(this.start),
            scroll: ko.unwrap(this.scroll)
        }
        return util.objectToQueryString(query)
    }
}

function SectionFilter(key, label) {
    this.key = key
    this.label = label
    this.isSelected = false
}

function SiteSearch(searchConfig) {
    var self = this;
    this.query = new Query(searchConfig)
    this.gridData = []
    this.hasMoreResults = true
    this.IsSearchRunning = false // capitalized to work with existing infinite scroll label view
    this.sectionFilters = searchConfig.sectionFilters.map(function (item) {
        return new SectionFilter(item.key, item.label)
    })

    if (this.query.section) {
        var selected = this.query.section;
        this.sectionFilters.forEach(function (filter) {
            if (filter.key === selected) {
                filter.isSelected = true;
            }
        });
    }

    self.loadNextPage = function () {
      var start = ko.unwrap(this.query.start)
      var num = ko.unwrap(this.query.num)
      this.query.scroll($(window).scrollTop())
      this.query.start(start + 1)
      this.updateQuery()
      setTimeout(function(){$('.list').find('a').eq((start)*num).focus();}, 600);
    }

    self.updateQuery = function () {
        var self = this
        self.query.section(null)
        ko.utils.arrayForEach(self.sectionFilters(), function (filter) {
            if (filter.isSelected()) {
                self.query.section(filter.key())
            }
        })
        var qs = '?' + self.query.toQueryString()
        history.pushState({qs: qs}, document.title, qs)
        this.search()
        $("html, body").animate({ scrollTop: this.query.scroll() }, "slow")
    }

    self.updateFilters = function () {
        this.query.start(1)
        this.query.scroll(0)
        this.updateQuery()
    }

    self.search = function () {
        $('body').css('opacity', '0.5')
        this.IsSearchRunning(true)
        var thing = this
        $.ajax({
            type: "POST",
            url: ko.unwrap(this.query.url),
            data: this.query.toRequestObj(),
            dataType: 'json',
            success: function (response) {
                thing.IsSearchRunning(false)
                if (response && response.results && response.results.length > 0) {
                    thing.hasMoreResults(response.hasMoreResults)
                    handleData({ 
                        items: response.results.map(function (x) {
                            return {
                                link: x.Link,
                                title: x.Title,
                                htmlSnippet: x.HtmlSnippet
                            }
                        })
                    })
                }
                else {
                    handleData({ items: [] });
                }
            },
            error: function (error) {
                thing.IsSearchRunning(false)
                handleData({ items: [] });
            }
        })

        this.query.loadAll(false);
    }

    self.clearFilters = function () {
        var sectionFilters = ko.unwrap(this.sectionFilters)
        sectionFilters.forEach(function (filter) {
            filter.isSelected(false)
        })
    }

    self.expandFilter = function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('active');
    }

    self.historyChange = function (data) {
        this.query.start(!!data.start ? parseInt(data.start) : 1)
        this.query.scroll(!!data.scroll ? parseInt(data.scroll) : 0)
        this.query.keyword(data.keyword)
        this.query.section(data.section)

        var section = data.section ?? '';

        var sectionFilters = ko.unwrap(this.sectionFilters)
        sectionFilters.forEach(function (filter) {
            if (filter.key() === data.section) {
                filter.isSelected(true);
            } else {
                filter.isSelected(false);
            }
        });

        this.gridData.removeAll()
        this.query.loadAll(true);
        this.search()

        $("html, body").animate({ scrollTop: this.query.scroll() }, "slow")
    }
}

function init(searchConfig) {
    var viewModel = ko.mapping.fromJS(new SiteSearch(searchConfig))

    window.addEventListener("popstate", (event) => {
        var newQuery = util.parseQueryString();
        viewModel.historyChange(newQuery);
    });

    ko.applyBindings(viewModel, $('.grid-content').get(0))

    viewModel.searchResultClick = function () {
      viewModel.query.scroll($(window).scrollTop())
      var qs = '?' + viewModel.query.toQueryString()
      history.replaceState({qs: qs}, document.title, qs)
      return true
    }

    return viewModel
}

function initHeader(url) {
    var ss = $('#header-site-search')
    var input = ss.find('.search-input')
    var button = ss.find('.search-button')
    input.on('keyup', function (e) {
        e.preventDefault()
        e.stopPropagation()
        var typeahead = $(this).parent().find('.typeahead')
        var keyword = input.val()
        if (e.keyCode == 13 && keyword.length > 0 && $('.typeahead-list').find('.selected').length <= 0) {
            window.location.href = url + '?keyword=' + encodeURIComponent(keyword)
        }
    })

    button.click(function (e) {
        e.preventDefault()
        e.stopPropagation()
        var keyword = input.val()
        if (keyword.length > 0) {
            window.location.href = url + '?keyword=' + encodeURIComponent(keyword)
        }
    })
}

module.exports = {
    init: init,
    initHeader: initHeader,
    Query: Query,
    SiteSearch: SiteSearch
}