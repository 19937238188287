var util = require('util')

$(document).ready(function () {
    var cookieVal = Cookies.get('ap-cookie');
    //console.log(cookieVal);

    // Add return URL parm so we can send user back after saving cookie preferences
    $linkToCookiePolicyForm = $("#sliding-popup a");
    $linkToCookiePolicyForm.attr("href", function (i, href) {
        var hrefAlreadyHasQueryParams = href.indexOf("?") !== -1;
        var returnUrl = window.location.href;
        return hrefAlreadyHasQueryParams
            ? href + "&returnUrl=" + encodeURIComponent(returnUrl)
            : href + "?returnUrl=" + encodeURIComponent(returnUrl);
    });

    if (!cookieVal) {
        $("#sliding-popup").addClass('is-animating');
        $("#CookieYes").prop("checked", false);
        $("#CookieNo").prop("checked", true);
        Cookies.set('ap-cookie', '1', { expires: 1000, path: "/", sameSite: 'lax' });
    }
    else if (cookieVal==="1") {
        $("#sliding-popup").hide();
        $("#CookieYes").prop("checked", true);
        $("#CookieNo").prop("checked", false);

    } else if (cookieVal === "0") {
        $("#sliding-popup").hide();
        $("#CookieYes").prop("checked", false);
        $("#CookieNo").prop("checked", true);
        //console.log('animate');
    }

    $(".agree-button").on("click", function () {
        Cookies.set('ap-cookie', '1', { expires: 1000, path: "/", sameSite: 'lax' });
        $("#sliding-popup").fadeOut();
    });

    $(".js-cookie-submit").on("click", function () {
        var check = $(".js-cookie-options input[type='radio']:checked").val();
        //console.log(check);
        if (check === "1" && !(cookieVal==="1"))
            Cookies.set('ap-cookie', '1', { expires: 1000, path: "/", sameSite: 'lax' });
        else if (check === "0") {
            //console.log('remove');
            Cookies.remove('ap-cookie', { path: '/' });
            Cookies.set('ap-cookie', '0', { expires: 1000, path: "/", sameSite: 'lax' });
        }
        // Send user back to URL they navigated from
        var returnUrl = util.parseQueryString(window.location.search).returnUrl;
        if (returnUrl) {
            open(returnUrl, "_self");
        }
    });
});