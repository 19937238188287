;(function ($) {
  'use strict'
  //On DOM Ready
  $(function () {
    var el = $('.subscribe-cta')

    if (el.length === 0) {
      return
    }

    $(window).on('scroll', function () {
      var el = $('.subscribe-cta')
      var footerTop = $('.site-footer').offset().top
      var windowBottom = $(window).scrollTop() + $(window).height()
      if (windowBottom > footerTop) {
        el.css({
          position: 'absolute',
          top: $('body').height() - $('.site-footer').height() - 100
        })
      } else {
        el.css({ position: 'fixed', top: 'auto' })
      }
    })
  })
})(jQuery)
