(function ($) {
	'use strict';

	$(document).ready(function bindSideFilterEventHandler() {

		var pageHasSideFilter,
			$sideFilter,
			$filterBtnMobile,
			$filterCategoryNames,
			$parentServices;

		$sideFilter = $('.side-filter')[0];
		$filterBtnMobile = $('.side-filter__mobile-btn');
		pageHasSideFilter = $sideFilter !== undefined;

		
		makeKeyboardAccessible(document.querySelectorAll('.filter-item-checkbox'));
		makeKeyboardAccessible(document.querySelectorAll('.fa.fa-times-circle'));
		makeKeyboardAccessible(document.querySelectorAll('.filter-category-name'));


		if (pageHasSideFilter) {
			$filterCategoryNames = $('.filter-category-name');

			if (window.innerWidth < 960) {
				$filterBtnMobile.on('click', function () {
					$('.side-filter').toggleClass('active');
					$('.side-filter__chevron').toggleClass('active-chev');
				});
			}

			if ($filterCategoryNames.length > 0) {
				$filterCategoryNames.on('click', handleFilterNameClick);
				//Accessibility: Make outer elements accessible by keyboard-only users
				makeKeyboardAccessible(document.querySelectorAll('.filter-category-name'));
			}

			//handle parent filter handler
			$parentServices = $('.filter-item .expand-icon');
			//first position appropriately

			//$parentServices.css('left',parseInt($('.filter-list ul .filter-item:first-child').width() - 8) + 'px');
		}

		function handleFilterNameClick() {
			$(this).parent().toggleClass('active');
			$(this).attr("aria-expanded", (_, attr) => attr == "true" ? "false" : "true");
			$(this).siblings('.filter-list').find('.filter-item-checkbox').attr("tabindex", (_, attr) => attr == "-1" ? "0" : "-1");
			$(this).siblings('.filter-list').find('.filter-item-checkbox').attr("aria-hidden", (_, attr) => attr == "false" ? "true" : "false");
			$(this).siblings('.filter-list').find('.filter-search').attr("tabindex", (_, attr) => attr == "-1" ? "0" : "-1");
			$(this).siblings('.filter-list').find('.filter-search').attr("aria-hidden", (_, attr) => attr == "false" ? "true" : "false");
		}

		//Accessibility: Make items on page accessible by keyboard-only users
		function makeKeyboardAccessible (item) {
			item.forEach(el => {
				el.addEventListener('keydown', e => {
					const keyDown = e.key !== undefined ? e.key : e.keyCode;
					if ( (keyDown === 'Enter' || keyDown === 13) || (['Spacebar', ' '].indexOf(keyDown) >= 0 || keyDown === 32)) {
						// (prevent default so the page doesn't scroll when pressing space)
						e.preventDefault();
						el.click();
					}
				});
			}); 
		}
	});
})($);