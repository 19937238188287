(function ($) {
    'use strict';

    $(document).ready(function bindCareersNavHandlers() {

        var $navSections,
            $selectedSection,
            $notSelectedSections,
            $closeBtn,
            selectedClass,
            notSelectedClass,
            selectedMobileClass,
            notSelectedMobileClass,
            hasCareersNav;

        selectedClass = 'selected';
        notSelectedClass = 'not-selected';
        notSelectedMobileClass = 'not-selected-mobile';
        selectedMobileClass = 'selected-mobile';

        hasCareersNav = $('.careers-nav').length > 0;

        // var width = $(window).width();
        // if (hasCareersNav && width > 780) {
        // 	$navSections  = $('.careers-nav-section');
        // 	$closeBtn = $('.section-close-btn');

        // 	if ($navSections.length > 0){
        //         $navSections.hover(makeSelectionActive, makeSelectionInactive);
        // 		$navSections.click(handleSectionClick);
        // 		$closeBtn.click(handleCloseBtnClick);
        // 		// $navSections.on('hover', makeSelectionActive, makeSelectionInactive);
        // 		// $navSections.on('click', handleSectionClick);
        // 		// $closeBtn.on('click', handleCloseBtnClick);
        // 	}
        // }else{
        //     //mobile view
        //     $navSections  = $('.careers-nav-section');
        // 	$closeBtn = $('.section-close-btn');


        //     if ($navSections.length > 0){
        // 		$navSections.click(handleSectionClickMobile);
        // 		$closeBtn.click(handleCloseBtnClickMobile);
        //         $(".section-links").addClass("mobile-hidden");
        //         // $navSections.on('click',handleSectionClickMobile);
        // 		// $closeBtn.on('click', handleCloseBtnClickMobile);
        //         // $(".section-links").addClass("mobile-hidden");
        // 	}
        // }
        var width = window.innerWidth;
        if (hasCareersNav && width > 1400) {
            $navSections = $('.careers-nav-section');
            $closeBtn = $('.section-close-btn');

            if ($navSections.length > 0) {
                $navSections.unbind('click');
                $closeBtn.unbind('click');
                $(".section-links").removeClass("mobile-hidden");
                $navSections.hover(makeSelectionActive, makeSelectionInactive);
                $navSections.focusin(makeSelectionActive);
                $navSections.focusout(makeSelectionInactive);
                $navSections.click(handleSectionClick);
                $closeBtn.click(handleCloseBtnClick);
                // $navSections.off('click', handleSectionClickMobile);
                // $closeBtn.off('click', handleCloseBtnClickMobile);
                // $(".section-links").removeClass("mobile-hidden");
                // $navSections.on('hover', makeSelectionActive, makeSelectionInactive);
                // $navSections.on('click', handleSectionClick);
                // $closeBtn.on('click',handleCloseBtnClick);
            }
        } else {
            //mobile view
            $navSections = $('.careers-nav-section');
            $closeBtn = $('.section-close-btn');


            if ($navSections.length > 0) {

                // $navSections.off('hover', makeSelectionActive, makeSelectionInactive);
                // $navSections.off('click', handleSectionClick);
                // $closeBtn.off('click',handleCloseBtnClick);
                // $navSections.on('click', handleSectionClickMobile);
                // $closeBtn.on('click', handleCloseBtnClickMobile);
                // $(".section-links").addClass("mobile-hidden");
                $navSections.unbind('mouseenter mouseleave');
                $navSections.unbind('click');
                $closeBtn.unbind('click');
                $navSections.click(handleSectionClickMobile);
                $closeBtn.click(handleCloseBtnClickMobile);
                $(".section-links").addClass("mobile-hidden");
            }
        }
        $(window).resize(function () {
            var width = window.innerWidth;
            if (hasCareersNav && width > 1400) {

                $navSections = $('.careers-nav-section');
                $closeBtn = $('.section-close-btn');

                if ($navSections.length > 0) {
                    //set heights      
                    //height: 365px;

                    $navSections.each(function (index, value) {
                        if ($(value).hasClass(selectedMobileClass)) {
                            $(value).removeClass(selectedMobileClass);
                        }
                        if ($(value).hasClass(notSelectedMobileClass)) {
                            $(value).removeClass(notSelectedMobileClass);
                        }
                        $(value).find('.section-wrapper').height(365);
                    });
                    $navSections.unbind('click');
                    $navSections.unbind('focusin');
                    $navSections.unbind('focusout');
                    $closeBtn.unbind('click');
                    $(".section-links").removeClass("mobile-hidden");
                    $navSections.hover(makeSelectionActive, makeSelectionInactive);
                    $navSections.focusin(makeSelectionActive);
                    $navSections.focusout(makeSelectionInactive);
                    $navSections.click(handleSectionClick);
                    $closeBtn.click(handleCloseBtnClick);
                    // $navSections.off('click', handleSectionClickMobile);
                    // $closeBtn.off('click', handleCloseBtnClickMobile);
                    // $(".section-links").removeClass("mobile-hidden");
                    // $navSections.on('hover', makeSelectionActive, makeSelectionInactive);
                    // $navSections.on('click', handleSectionClick);
                    // $closeBtn.on('click',handleCloseBtnClick);
                }
            } else {
                //mobile view
                $navSections = $('.careers-nav-section');
                $closeBtn = $('.section-close-btn');

                if ($navSections.length > 0) {

                    $navSections.each(function (index, value) {
                        if ($(value).hasClass(selectedClass)) {
                            $(value).removeClass(selectedClass);
                        }
                        if ($(value).hasClass(notSelectedClass)) {
                            $(value).removeClass(notSelectedClass);
                        }
                        $(value).find('.section-wrapper').height('auto');
                    });

                    //set heights
                    // $navSections.off('hover', makeSelectionActive, makeSelectionInactive);
                    // $navSections.off('click', handleSectionClick);
                    // $closeBtn.off('click',handleCloseBtnClick);
                    // $navSections.on('click', handleSectionClickMobile);
                    // $closeBtn.on('click', handleCloseBtnClickMobile);
                    // $(".section-links").addClass("mobile-hidden");
                    $navSections.unbind('mouseenter mouseleave');
                    $navSections.unbind('click');
                    $navSections.unbind('focusin');
                    $navSections.unbind('focusout');
                    $closeBtn.unbind('click');
                    $navSections.click(handleSectionClickMobile);
                    $closeBtn.click(handleCloseBtnClickMobile);
                    $(".section-links").addClass("mobile-hidden");
                }
            }
        });

        function makeSelectionActive() {
            $selectedSection = $(this);
            $notSelectedSections = $navSections.slice().not(this);
            var expandingingheight = $(this).find(".section-links").height();

            $notSelectedSections.addClass(notSelectedClass);
            $selectedSection.addClass(selectedClass);
            $selectedSection.find(".section-wrapper").height(expandingingheight);
            $notSelectedSections.each(function (index, value) {
                $(value).find(".section-wrapper").height(expandingingheight);
            });
        }

        function makeSelectionActiveMobile() {
            $selectedSection = $(this);
            $notSelectedSections = $navSections.slice().not(this);
            // var expandingingheight = $(this).find(".section-links").height();
            // $selectedSection.find(".section-wrapper").height(expandingingheight);

            $notSelectedSections.addClass(notSelectedMobileClass);
            $selectedSection.addClass(selectedMobileClass);
            $selectedSection.attr("tabindex", "-1");
            $selectedSection.find(".section-links").removeClass("mobile-hidden");
            $selectedSection.find('.section-close-btn').attr("tabindex", "0");
            $selectedSection.find('.section-close-btn').attr("aria-hidden", "false");
            $selectedSection.find('.section-header').attr("tabindex", "-1");
            $selectedSection.find('.section-header').attr("aria-expanded", "true");
            $selectedSection.find('.section-link').find('a')[0].focus();
            //$selectedSection.addClass(selectedClass);
        }

        function makeSelectionInactive() {

            var expandingingheight = 365;

            //$selectedSection.find(".section-wrapper").height(expandingingheight);
            $selectedSection.find(".section-wrapper").height(expandingingheight - 72);
            $notSelectedSections.each(function (index, value) {
                //$(value).find(".section-wrapper").height(expandingingheight);
                $(value).find(".section-wrapper").height(expandingingheight - 72);
            });

            $selectedSection.removeClass(selectedClass);
            $notSelectedSections.removeClass(notSelectedClass);
        }

        function makeSelectionInactiveMobile() {
            //$(this).removeClass(selectedMobileClass);
            //$(this).find('.section-wrapper').height('auto');
            $selectedSection.removeClass(selectedMobileClass);
            //$selectedSection.find('.section-wrapper').height(70);
            $selectedSection.find('.section-wrapper').height('auto');
            $selectedSection.find(".section-links").addClass("mobile-hidden");
            // $notSelectedSections.each(function (index, value) {
            //     //$(value).find(".section-wrapper").height(expandingingheight);
            //     $(value).find(".section-wrapper").height('auto');
            //     $(value).removeClass(selectedMobileClass);
            //     $(value).find(".section-links").addClass("mobile-hidden");
            // });
            $selectedSection.find('.section-close-btn').attr("tabindex", "-1");
            $selectedSection.find('.section-close-btn').attr("aria-hidden", "true");
            $selectedSection.find('.section-header').attr("tabindex", "0");
            $selectedSection.find('.section-header').attr("aria-expanded", "false");
            $selectedSection.find('.section-header').focus();
            //$notSelectedSections.removeClass(notSelectedMobileClass);
            //$selectedSection.removeClass(notSelectedMobileClass);
            //$selectedSection.find('.section-wrapper').height('auto');
            //$notSelectedSections.removeClass(selectedMobileClass);
            //$selectedSection.removeClass(notSelectedMobileClass);
            //$selectedSection.find('.section-wrapper').height('auto');
            //$notSelectedSections.removeClass(selectedMobileClass);

        }

        function handleSectionClick() {
            var $thisSection,
                sectionIsAlreadySelected;

            $thisSection = $(this);
            sectionIsAlreadySelected = $thisSection.hasClass(selectedClass);

            if (sectionIsAlreadySelected) {
                return;
            } else {
                makeSelectionActive.apply(this);
            }
        }

        function handleSectionClickMobile() {
            var $thisSection,
                sectionIsAlreadySelected;

            $thisSection = $(this);
            sectionIsAlreadySelected = $thisSection.hasClass(selectedMobileClass);

            if (sectionIsAlreadySelected) {
                return;
            } else {
                makeSelectionActiveMobile.apply(this);
                $thisSection.addClass(selectedMobileClass);
                $notSelectedSections.addClass(notSelectedMobileClass);
            }
        }

        function handleCloseBtnClick(e) {
            makeSelectionInactive();
            e.stopPropagation();
        }

        function handleCloseBtnClickMobile(e) {
            $selectedSection = $(this).parents().eq(1)
            makeSelectionInactiveMobile();
            e.stopPropagation();
        }

        //Accessibility: Make section-close-btn button activate for keyboard only users
        function makeKeyboardAccessible (item) {
			item.each(function() {
				$(this).on('keydown', e => {
					const keyDown = e.key !== undefined ? e.key : e.keyCode;
					if ( (keyDown === 'Enter' || keyDown === 13) ) {
						// (prevent default so the page doesn't scroll when pressing space)
						e.preventDefault();
                        var width = window.innerWidth;
                        if (hasCareersNav && width > 1400) {
						    handleCloseBtnClick(e);
                        }
                        else{
                            handleCloseBtnClickMobile.call(this, e);
                        }
					}
				});
			}); 
		}
    

    });
})($);