var ko = (typeof window !== "undefined" ? window['ko'] : typeof global !== "undefined" ? global['ko'] : null)

function parseQueryString(str) {
    str = str || window.location.search
    if (typeof str !== 'string') {
        return {};
    }
    str = str.trim().replace(/^\?/, '');
    if (!str) {
        return {};
    }
    return str.trim().split('&').reduce(function (ret, param) {
        var parts = param.replace(/\+/g, ' ').split('=');
        ret[parts[0]] = parts[1] === undefined ? null : decodeURIComponent(parts[1]);
        return ret;
    }, {});
}

function getDocHeight() {
    return Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
    );
}

function Cookie(name, value, days) {
    this.name = name
    this.value = value
    if (!!days) {
        var d = Date.now()
        this.expires = new Date(d + 365 * 24 * 60 * 60 * 1000)
    } else {
        this.expires = null
    }
}

Cookie.prototype.set = function (days) {
    if (!!days) {
        var d = Date.now()
        this.expires = new Date(d + 365 * 24 * 60 * 60 * 1000)
    }
    if (!!!this.expires) {
        document.cookie = this.name + '=' + this.value + '; path=/';
    } else {
        document.cookie = this.name + '=' + this.value + '; ' + this.expires + '; path=/';
    }
}

function getCookieCollection() {
    var cookies = document.cookie
    if (!!cookies && cookies.length > 0) {
        var obj = new Object
        return cookies
            .split('; ')
            .reduce(function (prev, curr) {
                var pair = curr.split('=')
                prev[pair[0]] = pair[1]
                return prev
            }, obj)
    }
}

function queryStringToObject(queryString) {
    //get query object from querystring
    queryString = queryString || window.location.search.substr(1)
    var obj = new Object
    return queryString
        .split('&')
        .reduce(function (prev, curr) {
            var pair = curr
                .split('=')
                .map(function (item) {
                    return decodeURIComponent(item)
                })
            prev[pair[0]] = pair[1]
            return prev
        }, obj)
}

function objectToQueryString(query) {
    query = ko.utils.unwrapObservable(query)
    return _.reduce(query, function (prev, value, key) {
        var value = ko.utils.unwrapObservable(value)
        if (prev.length > 0 && !!value) {
            prev += '&'
        }
        if (!!value && value != '') {
            return prev + decodeURIComponent(key) + '=' + decodeURIComponent(value.toString())
        } else {
            return prev
        }
    }, '')
}

// object extensions
HTMLElement.prototype.getTop = function () {
    var curtop = 0;
    var obj = this;
    if (obj.offsetParent) {
        do {
            curtop += obj.offsetTop;
        } while (obj = obj.offsetParent);
        return curtop;
    }
}

function max(a, b) {
    return a >= b ? a : b;
}

function getQueryParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function parseBool(string) {
    if (string == 'true') return true;
    if (string == 'false') return false;
}

module.exports = {
    parseQueryString: parseQueryString,
    getDocHeight: getDocHeight,
    Cookie: Cookie,
    getCookieCollection: getCookieCollection,
    queryStringToObject : queryStringToObject,
    objectToQueryString: objectToQueryString,
    max: max,
    getQueryParameterByName: getQueryParameterByName
}