document.addEventListener("DOMContentLoaded", function () {
    function calculateOffset() {
        var headerHeight = document.querySelector('.site-header')?.offsetHeight || 0;
        var navbarHeight = document.querySelector('.site-subnav-ul')?.offsetHeight;
        navbarHeight = navbarHeight > 0 ? navbarHeight : 50;

        return headerHeight + navbarHeight;
    }

    document.querySelectorAll('a[href^="#"]').forEach(function (anchor) {
        anchor.addEventListener('click', function (e) {
            var href = this.getAttribute('href');
            if (href.startsWith("#") && href.length > 1) {
                e.preventDefault();
                var targetName = decodeURIComponent(href.slice(1));
                var target = document.querySelector('a[name="' + targetName + '"]');

                if (target) {
                    var offset = calculateOffset();
                    window.scrollTo({
                        top: target.getBoundingClientRect().top + window.scrollY - offset,
                        behavior: 'smooth'
                    });
                }
            }
        });
    });
});
