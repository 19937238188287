$(document).ready(function () {
    //jquery on click callback
    function handleEmailOnClick(e) {
        e.preventDefault()
        e.stopPropagation()
        var thisAttr = $(this).attr('href')
        $('.email-link').attr('value', thisAttr)
        $('#email-modal').addClass('active')
    }

    function setupOnClickForEmail() {
        //Remove event handler in case it already exists
        //$('a[href^="mailto:"], a[href^=" mailto:"]').off('click', handleEmailOnClick)

        //Handle on click for email
        //$('a[href^="mailto:"], a[href^=" mailto:"]').click(handleEmailOnClick)
    }

    setupOnClickForEmail()
    window.setupOnClickForEmail = setupOnClickForEmail

    //Accept disclaimer
    $('#email-modal .btn.close.accept-disclaimer').click(function (e) {
        e.preventDefault()
        e.stopPropagation()

        $('#email-modal').removeClass('active')
        window.location = $('.email-link').attr('value')
    })

    //Reject disclaimer
    $('#email-modal .btn.close.reject-disclaimer').click(function (e) {
        e.preventDefault()
        e.stopPropagation()

        $('#email-modal').removeClass('active')
    })
})   
