var updateMasonryPositions = require('./../components/cardGridMasonry')

$(document).ready(function () {

    var $home = $('.home');
    var $accordion = $('.homepage-hero__accordion');
    var $accordionTrigger = $('.homepage-hero__accordion-title');
    var $accordionContent = $('.homepage-hero__list-items');
    var $masonry = $('.card-grid__cards');

    if ($home.length === 0) {
        return;
    }

    var cookieVal = Cookies.get('why_arnold-cookie');

    function setMaxHeight() {
        if ($(window).width() < 945) { // 960
            $('.card-grid__content').css({ 'height': 'auto', 'margin-top': 0 });
            $('.card-grid__further-reading').css('min-height', 'auto');
        } else {
            var maxHeight = 0;
            var tileHeight = 0;
            var tileSpacing = $('.card-grid__card').css('margin-bottom');
            $('.card-grid__card').each(function () {
                var contentHeight = $(this).find('.card-grid__eyebrow').outerHeight(true) + $(this).find('.card-grid__title').outerHeight(true) + $(this).find('.card-grid__read-more-link').outerHeight(true);
                if (contentHeight > maxHeight) {
                    maxHeight = contentHeight;

                    var isNormalTile = !$(this).hasClass('card-grid__card--md-v') && !$(this).hasClass('card-grid__card--lg');
                    if (isNormalTile) {
                        tileHeight = $(this).outerHeight() + parseInt(tileSpacing);
                    }
                }
            })

            $('.card-grid__content').height(maxHeight);

            $('.card-grid__card--md-v, .card-grid__card--lg').each(function () {
                $(this).find('.card-grid__content').css('margin-top', parseInt(tileHeight));
            })

            $('.card-grid__further-reading').css('min-height', tileHeight * 2 - parseInt(tileSpacing));
        }
    }

    if (cookieVal) {
        $accordion.addClass('homepage-hero__accordion--cookie');
        $('.homepage-hero__accordion-title').attr("aria-expanded", "false");
    }

    $accordionTrigger.on('click', function () {
        if ($accordion.is('.homepage-hero__accordion--cookie, .homepage-hero__accordion--hide')) {
            Cookies.remove('why_arnold-cookie', { path: '/' });
            $accordionContent.slideDown();
            $accordion.removeClass('homepage-hero__accordion--hide homepage-hero__accordion--cookie');
            $('.homepage-hero__accordion-title').attr("aria-expanded", "true");
        } else {
            Cookies.get('why_arnold-cookie', '1', { expires: 1000, path: '/' });
            $accordionContent.slideUp();
            $accordion.addClass('homepage-hero__accordion--hide');
            $('.homepage-hero__accordion-title').attr("aria-expanded", "false")
        }
    });

    //Accessibility: Make items on page accessible by keyboard-only users
    function makeKeyboardAccessible (item) {
        item.each(function () {
            $(this)[0].addEventListener('keydown', e => {
                const keyDown = e.key !== undefined ? e.key : e.keyCode;
                if ( (keyDown === 'Enter' || keyDown === 13) || (['Spacebar', ' '].indexOf(keyDown) >= 0 || keyDown === 32)) {
                    // (prevent default so the page doesn't scroll when pressing space)
                    e.preventDefault();
                    $(this)[0].click();
                }
            });
        }); 
    }
    makeKeyboardAccessible($accordionTrigger);

    // Check to see if browser supports css grids
    var supportsCssGrid = typeof document.createElement('div').style.grid === 'string'
    if (supportsCssGrid) {
        updateMasonryPositions($masonry)
    // If browser doesn't support css grids, we fallback to using masonry.js
    } else {
        $(window).on('resize', _.debounce(function updateLayout () {
            setMaxHeight();
            $masonry.masonry();
        }, 500));

        setMaxHeight();

        $masonry.masonry({
            columnWidth: '.masonry-grid',
            itemSelector: '.masonry-card',
            gutter: '.masonry-gutter',
            percentPosition: true,
            // resize: false,
            // initLayout: false
        });
    }
})
