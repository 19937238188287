(function ($) {
    'use strict';
    var printWin = null;

    //On Ready
    $(document).ready(function () {

        //Handle print clicks
        $('.page-tools-icon.js-print').click(function() {

            function printPage(sURL) {
                if (printWin == null) {
                    printWin = window.open(sURL, '_blank', "height=792,width=612");
                    printWin.focus();
                    $(printWin).ready(function () {
                        printWin.onunload = function () {
                            printWin = null;
                        };
                        printWin.print();
                        
                    });
                } else {
                    printWin.focus();
                    printWin.print();
                }
                
            }

            printPage(window.location.href + (!!window.location.search ? '&p=1' : '?p=1'));
        });


        //Accessibility: Display expanded/collapsed state for addthis button
        $('.addthis_button').click(toggleShare)
        function toggleShare() {
            $('.addthis_button').attr("aria-expanded", (_, attr) => attr == "true" ? "false" : "true");
        }
    });
})(jQuery);
