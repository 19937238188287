(function($){
'use strict';

	$(document).ready(function bindExploreCasesHandlers(){

		var $exploreCases,
			$caseNames,
			$activeContent,
			$activeContentCaseName,
			$activeContent1,
			$activeContentCaseName1,
			pageHasExploreCases,
			activeClass;

		$exploreCases = $('.explore-cases');
		$activeContent = null;
		$activeContentCaseName = null;
		$activeContent1 = null;
		$activeContentCaseName1 = null;
		pageHasExploreCases = $exploreCases.length > 0;
		activeClass = 'active';

		if (pageHasExploreCases){
			$caseNames = $exploreCases.find('.case-name');

			if ($caseNames.length > 0){
				$caseNames.click(handleCaseNameClick);
			}
		}

		function handleCaseNameClick(){
			var $newActiveContent,
				$newActiveContentCaseName;

			$newActiveContentCaseName = $(this);
			$newActiveContent = $newActiveContentCaseName.parent().find('.case-content').first();

			var $currentActiveContent = $activeContent;
			var $currentActiveContentCaseName = $activeContentCaseName;
			if($newActiveContentCaseName.hasClass('level1')){
				$currentActiveContent = $activeContent1;
				$currentActiveContentCaseName = $activeContentCaseName1;
			}
			if ($currentActiveContent !== null) {

				if ($newActiveContent[0] === $currentActiveContent[0]){
					$currentActiveContent.slideToggle();
					$currentActiveContentCaseName.toggleClass(activeClass);
					return;
				}

				$currentActiveContent.slideUp();
				$currentActiveContentCaseName.removeClass(activeClass);
			}

			$newActiveContent.slideDown();
			$newActiveContentCaseName.addClass(activeClass);

			if($newActiveContentCaseName.hasClass('level1')){
				$activeContent1 = $newActiveContent;
				$activeContentCaseName1 = $newActiveContentCaseName;	
			}else{
				$activeContent = $newActiveContent;
				$activeContentCaseName = $newActiveContentCaseName;	
			}
		}
	});

})($);