'use strict'

function updateFacadeText(selectLabel) {
    var $this = $(this),
        $selectedItem,
        $selectLabel,
        currentText

    $selectedItem = $this.find(':selected')

    if (!$selectedItem.length) { return }

    // get the facade element used to display
    // the text of the selected option
    $selectLabel = $this.siblings(selectLabel)


    // get 'value' attribute value for selected option
    currentText = $selectedItem.text()


    // update select text label with current selected option value
    if ($selectLabel.length && currentText.length) {
        $selectLabel.text(currentText)
    }
}

//Accessibility: Make items on page accessible by keyboard-only users
function makeKeyboardAccessible (item) {
    item.forEach(el => {
        el.addEventListener('keydown', e => {
            const keyDown = e.key !== undefined ? e.key : e.keyCode;
            if ( (keyDown === 'Enter' || keyDown === 13) || (['Spacebar', ' '].indexOf(keyDown) >= 0 || keyDown === 32)) {
                // (prevent default so the page doesn't scroll when pressing space)
                e.preventDefault();
                el.click();
            }
        });
    }); 
}

$(document).ready(function () {
    // execute this once the DOMContentLoaded has fired (dom ready)
    $(function (context, targetEl, selectLabel) {

        var $dropdowns = $(context).find(targetEl)
        var eventNamespace = context

        if ($dropdowns.length) {
            $dropdowns
                .off(eventNamespace)
                .on('change' + eventNamespace, function () {
                    updateFacadeText.call(this, selectLabel)
                    setTimeout(function () {makeKeyboardAccessible(document.querySelectorAll('.fa.fa-times-circle'));}, 500);
                })
        }
    }('.select-dropdown', 'select', 'span'))

    $('.lang-dropdown').on('change', function(e) {
        window.location.href = $(this).find('select').val()
    })

    //Accessibility: provide visible focus indicator for select box
    $('.select-dropdown-box').on('focus', function(e) {
        $('.lang-dropdown').addClass('focused');
    })
    $('.select-dropdown-box').on('blur', function(e) {
        $('.lang-dropdown').removeClass('focused');
    })
    $('.select-dropdown-box').on('keydown', function(e){
        const keyDown = e.key !== undefined ? e.key : e.keyCode;
        //Up and down keys
        if(e.keyCode === 38 || e.keyCode === 40)  { 
            if(!e.altKey){
                e.preventDefault();
                return false;
            }
        }
    });
    //Accessibility: Provide visible focus indicator for select category dropdown
    $('.sortoption-select').on('focus', function(e) {
        $('.select-dropdown__full').focus()
        $('.select-dropdown').addClass('focused')
    })
    $('.sortoption-select').on('blur', function(e) {
        $('.select-dropdown__full').blur()
        $('.select-dropdown').removeClass('focused')
    })
    $('.sortoption-select').on('keydown', function(e){
        const keyDown = e.key !== undefined ? e.key : e.keyCode;
        //Up and down keys
        if(e.keyCode === 38 || e.keyCode === 40)  { 
            if(!e.altKey){
                e.preventDefault();
                return false;
            }
        }
    });

    //Accessibility: Make elements keyboard navigable
    setTimeout(function () {
        makeKeyboardAccessible(document.querySelectorAll('.fa.fa-times-circle'));
        makeKeyboardAccessible(document.querySelectorAll('.clear-all'));
    }, 500);
})