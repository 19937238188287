
'use strict';

//On Ready
$(function () { 
    //Position Trigger Right next to collapsed Content

    var $readMoreContent,

        readMoreNodes;

    
    function getReadMoreNodes() {
        readMoreNodes = [];
        $readMoreContent = $('.js-read-more');
    }

    //expandReadMore

    function setupReadMoreNodes(countNodeIndex) {

        var nodeProperties = {

            $contentNode: $readMoreContent.eq(countNodeIndex),

            $triggerBtn: $readMoreContent.eq(countNodeIndex).next(),

            collapsedLabel: $readMoreContent.eq(countNodeIndex).data('collapsed-text') || '- Collapse',

            visibility:  $readMoreContent.eq(countNodeIndex).data('initial-open') || 'false',

            nodeCount: countNodeIndex
        };



        nodeProperties.triggerLabel = nodeProperties.$triggerBtn.text();

        readMoreNodes.push(nodeProperties);       

        nodeProperties.$triggerBtn.off("click");

        //Event handler For TriggerButton
        nodeProperties.$triggerBtn.on('click.readMore', function() {
            toggleVisibility(countNodeIndex);
        });


        //Expand or Collapse read more based on data attribute
        (nodeProperties.visibility === true) ? expandReadMore(countNodeIndex) : collapseReadMore(countNodeIndex);
    }


    function expandReadMore(countNodeIndex) {
        readMoreNodes[countNodeIndex].visibility = true;

        readMoreNodes[countNodeIndex].$contentNode.slideDown();
        readMoreNodes[countNodeIndex].$triggerBtn.text(readMoreNodes[countNodeIndex].collapsedLabel);
        window.ReadMore = true;
        readMoreNodes[countNodeIndex].$triggerBtn.attr("aria-expanded", "true");
        $readMoreContent.eq(countNodeIndex).find('a').eq(0).focus();
    }

    function collapseReadMore(countNodeIndex) {
        readMoreNodes[countNodeIndex].visibility = false;

        readMoreNodes[countNodeIndex].$contentNode.slideUp();
        readMoreNodes[countNodeIndex].$triggerBtn.text( readMoreNodes[countNodeIndex].triggerLabel );
        window.ReadMore = false;
        readMoreNodes[countNodeIndex].$triggerBtn.attr("aria-expanded", "false");
    }

    function toggleVisibility(countNodeIndex) {

        if (readMoreNodes[countNodeIndex].visibility === true) {
            collapseReadMore(countNodeIndex);
        }
        else {
            expandReadMore(countNodeIndex);
        }
    }

    function initializeReadMore() {
        //Call getReadMoreNodes() in case any new readmore buttons were added into the dom
        getReadMoreNodes();

        if ($readMoreContent.length > 0) {

            for (var i = 0; i < $readMoreContent.length; i++) {
                setupReadMoreNodes(i);
            }
        }
    }

    initializeReadMore();

    window.initializeReadMore = initializeReadMore;
});
