(function($){
'use strict';

	$(document).ready(function bindFeaturedCasesEventHandlers(){

		var $featuredCases,
			$caseShowcase,
		    $caseDetails,
			$body,
		    $readMoreButton,
		    $readMoreDiv,
			headerOffset,
			pageHasFeaturedCases,		    
			pageHasCaseShowcase;

		$featuredCases = $('.featured-case');
		$caseShowcase = $('.case-showcase');
		$caseDetails = $('.case-details');
		$readMoreButton = $('.btn.btn-read-more');
		$readMoreDiv = $('.case-details-readmore');
	    

		pageHasFeaturedCases = $featuredCases.length > 0;
		pageHasCaseShowcase = $caseShowcase.length > 0;

		if (pageHasFeaturedCases && pageHasCaseShowcase) {

			$featuredCases.click(handleCaseClick);

			//cache selectors
			$body = $('body');			

			headerOffset = getHeaderOffset();
		}

		function handleCaseClick(){

		    $featuredCases.removeClass('active');
		    $caseDetails.css('display', 'none');
		    $readMoreDiv.css('display', 'none');
		    $readMoreButton.css('display', 'inline-block');

		    var $selectedElement = $(this);
		    var selectedId = $selectedElement.data('case-id');
		    var idOfArticle = 'feature' + selectedId;

		    $selectedElement.addClass('active');
		    var $selectedArtcileBlock = $('.case-details#' + idOfArticle);
		    $selectedArtcileBlock.css('display', 'block');
		    
		    var $caseImage = $selectedArtcileBlock.find('.case-image');
		    $body.animate({ scrollTop: $caseImage.offset().top - headerOffset });
		    return;
		}

		function getHeaderOffset(){
			var $header,
				$subNav,
				pageHasHeader,
				pageHasSubNav,
				offset;

			$header = $('.site-header');
			$subNav = $('.site-subnav');

			pageHasHeader = $header.length > 0;
			pageHasSubNav = $subNav.length >0;

			offset = 0;

			if (pageHasHeader){
				offset += $header.height();
			}

			if (pageHasSubNav){
				offset += $subNav.height();
			}

			return offset;
		}
	});

})($);