(function ($) {
    'use strict';
    // breakpoint sizes! - make sure these match _variables.scss
    var bpLgx = 1350,
        bpLg = 1100,
        bpMd = 960,
        bpSm = 700;
    ///
    /// Slick Js setup on homepage
    /// Main Stage carousel
    ///
    $(document).ready(function () {
        ///
        /// Slick Js setup on homepage
        ///
        // var $carousel = $('.carousel');
        // function setupSlick() {
        //     $carousel.slick({
        //         dots: false,
        //         prevArrow: '<button type="button" data-role="none" class="slick-prev fa fa-angle-left" aria-label="previous"></button>',
        //         nextArrow: '<button type="button" data-role="none" class="slick-next fa fa-angle-right" aria-label="next"></button>',
        //         responsive: [
        //             {
        //               breakpoint: bpSm,
        //               settings: {
        //                adaptiveHeight: true,
        //                dots: true
        //               }
        //             }
        //         ]
        //     });
        // }
        // if ($carousel.length > 0) {
        //     setupSlick();
        // }
        ///
        /// Main stage carousel
        ///
        var $mainCarousel = $('.main-stage-slide'),
            $prev = $('.home-hero .prev-page'),
            $next = $('.home-hero .next-page'),
            //Assuming the index will start at the first slide
            slideIndex = 0,
            slideLength = $mainCarousel.length - 1,
            slidePrevIndex = slideLength,
            slideNextIndex = slideIndex + 1,
            isAnimating = false;
        function animatePrev(e) {
            e.stopPropagation()
            if (isAnimating) {
                return;
            }
            isAnimating = true;
            slideNextIndex = slideIndex;
            --slideIndex;
            if (slideIndex < 0) {
                slideIndex = slideLength;
            }
            slidePrevIndex = slideIndex - 1;
            if (slidePrevIndex < 0) {
                slidePrevIndex = slideLength;
            }
            $mainCarousel.eq(slideNextIndex).addClass('zero-width');
            setTimeout(function customResetClasses() {
                $mainCarousel.eq(slideIndex).addClass('active');
                resetClasses();
            }, 1200);
        }

        function animateNext(e) {
            e.stopPropagation()
            if (isAnimating) {
                return;
            }
            isAnimating = true;
            slidePrevIndex = slideIndex;
            ++slideIndex;
            if (slideIndex > slideLength) {
                slideIndex = 0;
                slidePrevIndex = slideLength
            }
            slideNextIndex = slideIndex + 1;
            if (slideNextIndex > slideLength) {
                slideNextIndex = 0;
            }
            $mainCarousel.eq(slideIndex).addClass('active');
            setTimeout(resetClasses, 1200);
        }

        function resetClasses() {
            $mainCarousel
                .removeClass('active-prev')
                .eq(slidePrevIndex)
                .removeClass('active')
                .addClass('active-prev')
            $mainCarousel
                .removeClass('active-next')
                .eq(slideNextIndex)
                .removeClass('active zero-width')
                .addClass('active-next');
            isAnimating = false;
        }
        //Initialize Event Handlers
        $prev.click(animatePrev);
        $next.click(animateNext);
        resetClasses();
    });
    //Bring out ajax content when carousel article is clicked
    $(document).ready(function handleCarouselClick() {
        var $ajaxContent,
			$body,
			$windowShade;
        $body = $('body');
        $windowShade = $('.window-shade');
        
        // Handle click event for stories, but bind on the carousel and filter on the links.
        //   It appears Slick regenerates the elements when breakpoints are hit and bindings are lost.
        //   Then handle if we are desktop or mobile.
        //$('.carousel').on('click', '[data-ajax-insight-link]', function (e) {
        //    if (document.body.clientWidth <= bpSm) { // Handle click on mobile
        //        ($.proxy(handleClickOnMobile, this, e))();
        //    } else { //Handle Click on Desktop
        //        ($.proxy(handleClickOnDesktop, this, e))();
        //    }
        //});

        //function handleClickOnMobile(e) {
        //    e.preventDefault();
        //    var $thisJQueryObj = $(this);
        //    window.location = $thisJQueryObj.data('href');
        //}
        //function handleClickOnDesktop(e) {
        //    e.preventDefault();
        //    var $thisJQueryObj = $(this);
        //    var data = {
        //        'StoryGuid': $thisJQueryObj.data('ajax-insight-guid'),
        //        'FeatureGuid': $thisJQueryObj.data('feature-guid')
        //    };
        //    $.ajax({
        //        url: '/api/home/GetStoryPage',
        //        data: data
        //    }).done(function (result) {
        //        //Remove home-ajax-content if it already is in the dom
        //        $('.home-ajax-content').remove();

        //        //Insert html into dom after modal class
        //        $('.modal').after(result);

        //        $('.home-ajax-content').on('click', '[data-ajax-insight-link]', handleClickOnDesktop);

        //        var $ajaxContent = $('.home-ajax-content');
        //        setAjaxCloseEventHandlers($ajaxContent);

        //        $ajaxContent.addClass('active');
        //        initializeReadMore();
        //        setupOnClickForEmail();

        //        setTimeout(function fadeInAjaxContent() {
        //            $body.addClass('ajax-active');
        //            $windowShade.fadeIn();
        //        }, 20);
        //    });
        //}
        function setAjaxCloseEventHandlers($ajaxContent) {
            var $body = $('body'),
            $ajaxContentClose = $('.ajax-content-close'),
            $windowShade = $('.window-shade');
            //close ajax content
            function hideAjaxContent() {
                $body.removeClass('ajax-active');
                $windowShade.fadeOut();

                setTimeout(function () {
                    $ajaxContent.removeClass('active');
                }, 500);
            }
            // only set event once in order to throttle animation
            //   reset clicks after animations
            $ajaxContentClose.click(hideAjaxContent);
            $windowShade.click(hideAjaxContent);
        }
    });
    //Control homepage hero video playback (pause when in ios widths, play when not)
    $(document).ready(function controlHeroVideo() {
        var isHomePage,
			$win,
			iosWidth,
			wasIosWidth,
			hasWorkVideo,
			workVideo;
        iosWidth = 1024;
        $win = $(window);
        isHomePage = $('.main-stage-slide').length > 0;
        wasIosWidth = isIosWidth();
        if (isHomePage) {
            workVideo = $('.home-hero.work video')[0];
            hasWorkVideo = workVideo !== undefined;
            if (hasWorkVideo) {
                if (wasIosWidth) {
                    handleResizeVideo();
                }
                $win.resize(handleResizeVideo);
            }
        }
        function handleResizeVideo() {
            var currentIsIosWidth,
				goingOutOfIosWith,
				goingIntoIosWidth;
            currentIsIosWidth = isIosWidth();
            goingIntoIosWidth = !wasIosWidth && currentIsIosWidth;
            goingOutOfIosWith = wasIosWidth && !currentIsIosWidth;
            if (goingIntoIosWidth) {
                workVideo.pause();
                wasIosWidth = true;
            } else if (goingOutOfIosWith) {
                workVideo.play();
                wasIosWidth = false;
            }
        }
        function isIosWidth() {
            return $win.width() <= iosWidth;
        }
    });
})($);
