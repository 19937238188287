'use strict';

(function($){

	var $filterToggle = $('.js-filter-toggle');

	if ($filterToggle.length) {

	    var $dropdowns = $('.select-dropdown'),
	        $filterToggleBtn = $filterToggle.find('.js-simple-toggle');

	    $dropdowns.on('change.filterToggle', function(){
	        $filterToggleBtn.trigger('click.simpleToggle');
	    });
	}

})(jQuery);