var updateMasonryPositions = require('./../components/cardGridMasonry')

$(document).ready(function () {

  var $perspectives = $('.perspectives-landing');
  var $masonry = $('.card-grid__cards--masonry');

  if ($perspectives.length === 0) {
    return;
  }

  // Check to see if browser supports css grids
  var supportsCssGrid = typeof document.createElement('div').style.grid === 'string'
  if (supportsCssGrid) {
      updateMasonryPositions($masonry)
  // If browser doesn't support css grids, we fallback to using masonry.js
  } else {
    $(window).on('resize', _.debounce(function updateLayout () {
      $masonry.masonry();
    }, 500));


    $masonry.masonry({
      columnWidth: '.masonry-grid',
      itemSelector: '.masonry-card',
      gutter: '.masonry-gutter',
      percentPosition: true
    });
  }
})