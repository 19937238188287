'use strict'

$(function () {
  var $backToTop = $('.js-back-to-top'),
    $footer = $('.site-footer');

  if (!$backToTop) {
    return;
  }

  $(window).scroll(function () {
    var pastTheFilters = 515
    if (($(this).scrollTop() > pastTheFilters) && window.innerWidth > 767) {
      $backToTop.fadeIn()
    } else {
      $backToTop.fadeOut()
    }
  })

  $backToTop.click(function () {
    $('html, body').animate({
      scrollTop: 0
    }, 800);
    return false;
  })

  // $(window).scroll(function () {
  //   if ($(window).scrollTop() + $(window).height() > $(document).height() - $footer.height()) {
  //     alert("bottom!");
  //   }
  // });
})