;(function ($) {
  'use strict'

  //On DOM Ready
  $(function () {
    var $anchorTabs = $('.js-anchor-tabs')

    //Do we have this element on the page?? If so lets fire some events
    if ($anchorTabs.length > 0) {
      var $siteBody = $('html,body'),
          $tabTriggers = $anchorTabs.find('.site-anchor-tabs-link'),
          $tabSections = $('.js-anchor-tab-section'),
          $tabLabel = $('.site-anchor-tabs-label'),
          $window = $(window),
          windowHeight = window.innerHeight,
          windowWidth = window.innerWidth,
          mobileState = false,
          tabsHeight = $anchorTabs.outerHeight(),
          $footer = $('.site-footer'),
          footerOffset,
          pageOffset = calculateOffsets()

        tabEventHandler()
        jumpLinkEventHandler()
        attachScroll()
        windowResizeHandler()
        onWindowResize()        
    }

    function jumpLinkEventHandler() {
      $('a[href*="#"]').on('click', function (ev) {
        var targetLocation = $(ev.target.hash)
        if (targetLocation && targetLocation.offset()) {
          $siteBody.animate({ scrollTop: targetLocation.offset().top - pageOffset }, 700)
        }
      })
    }

    function tabEventHandler() {
      $tabTriggers.on('click.smoothScroll', function (ev) {
        var $this = $(this),
            targetLocation,
            targetName

        targetName = $this.data('anchor-name')
        targetLocation = $('#anchor-' + targetName)

        $siteBody.animate({ scrollTop: targetLocation.offset().top - pageOffset }, 700)
        $tabLabel.trigger('click.anchorLabel')
        $tabTriggers.removeClass('is-active')
        $this.removeClass('is-active')
        console.log(targetLocation.find('h2').eq(0))
        targetLocation.find('h2').eq(0).focus();
        return false
      })

      $tabLabel.on('click.anchorLabel', function (ev) {
        if (mobileState === true) {
          if ($anchorTabs.hasClass('is-expanded')) {
            $anchorTabs.removeClass('is-expanded')
          } else {
            $anchorTabs.addClass('is-expanded')
          }
        }
      })

      //update label on first load
      var labelText = $tabTriggers.filter('.is-active').text()
      $tabLabel.text(labelText)

      $tabTriggers.each(function (idx, el) {
        if (!$(el).text()) {
          $(el).addClass('is-empty')
        }
      })
    }

    function calculateOffsets() {
      //Value
      var headerHeight = $('.site-header').outerHeight(), // account for the top percentage offset
        subNavHeight = $('.site-subnav').outerHeight() || 0,
        personBarHeight = $('.people-card').length ? 50 : 0,
        fixedElOffset = headerHeight + subNavHeight + personBarHeight + 20
      footerOffset = $footer.offset().top - windowHeight + tabsHeight + 20
      return fixedElOffset
    }

    function checkSection(scrollYPosition) {
      var sectionIndex = -1
      $tabSections.each(function (index) {
        if (scrollYPosition >= parseInt($tabSections.eq(index).offset().top, 10) && scrollYPosition < $tabSections.eq(index).offset().top + $tabSections.eq(index).outerHeight()) {
          sectionIndex = index
        }
      })

      if (sectionIndex !== -1) {
        $tabTriggers.removeClass('is-active')
        $tabTriggers.eq(sectionIndex).addClass('is-active')
        $tabLabel.text($tabTriggers.eq(sectionIndex).text())
      } else {
        sectionIndex = -1
      }
    }

    function checkfooterPosition(scrollYPosition) {
      if (scrollYPosition > footerOffset) {
        $anchorTabs.addClass('is-unanchored')
      } else {
        $anchorTabs.removeClass('is-unanchored')
      }
    }

    function attachScroll() {
      $(window).on('scroll.anchorTabs', function () {
        checkSection(window.pageYOffset + pageOffset)
        checkfooterPosition(window.pageYOffset + pageOffset)
      })
    }

    function windowResizeHandler() {
      if (windowWidth <= 700)
        mobileState = true
      $(window).on('resize.anchorTabs', onWindowResize)
    }

    function onWindowResize() {
        var resizeTimer
        clearTimeout(resizeTimer)
        resizeTimer = setTimeout(function () {
            calculateOffsets()
            mobileState = window.innerWidth <= 700
        }, 300)
    }

    //Article tabs: handle accessibility updates 
    var $articleTags = $(".article-tag-link")
    //Do we have this element on the page?? If so lets fire some events
    if ($articleTags.length > 0) {
      const currentUrl = window.location.href
      const categoriesField = "categories"
      if(currentUrl.indexOf('?' + categoriesField + '=') != -1){
        $(".search-result-item")[0].focus();
      }
    }
  });
})(jQuery)