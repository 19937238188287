(function($){
'use strict';
	//On Ready
	$(function () { 
		var $personCard = $('.js-person-card');
        //Do we have this element on the page?? If so lets fire some events
        if ($personCard.length > 0) {
            var $body = $('body'),
                personCardHeight = $personCard.height();
            //NEED TO CALCUATW OFFSET
            //var counterOffset = calculateOffset();
            var $personBarDom = setupPersonBar();
        }
        function counterLogic(windowOffset) {
            if (windowOffset > personCardHeight) {
                $personBarDom.addClass('is-inview');
            }
            else {
                $personBarDom.removeClass('is-inview');
            }
        }
        function setupPersonBar() {
            //Obtain values
            var personName = $personCard.find('h1').text(),
                personLevel = $personCard.find('.person-level').text();
            //Create Markup and Append to the end of the body
            var $personBar = $('<div class="person-card-bar site-wrapper-fluid">');
            $personBar.append('<span class="person-card-bar-name">' + personName + '</span>');
            $body.append($personBar);
            //Register windowScrollListener
            windowListener.onScroll(counterLogic);
            return $personBar;
        }
	});
})(jQuery);