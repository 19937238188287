'use strict'
require('amcharts3/amcharts/amcharts');
require("amcharts3/amcharts/pie");
require("amcharts3/amcharts/serial");
require("amcharts3/amcharts/themes/light");

function amchartGraphicViewModel(data) {
    var  chartJsonData = JSON.parse(data.initialJsonData);
    if (data.chartType === "pie") {
        var  chart = AmCharts.makeChart("amchart-graphic", {
            "type": "pie",
            "theme": "light",
            "dataProvider": chartJsonData,
            "valueField": "ValueField",
            "titleField": "TitleField",
            "colorField": "ColorField",
            "legend": {
                "generateFromData": true,
                "position": "right"

            },
            "balloon": { "fixedPosition": true, },
            "balloonText": "[[BalloonText]]",
            "labelText": "[[LabelText]]"            
        });
        var  legendData = chart.dataProvider.map(function (data, idx) {
            var  markerData = {
                "title": data.LegendText,
                "color": data.ColorField,
                "dataIdx": idx
            };
            return markerData;
        });
        chart.legend.data = legendData;
    } else {
        var  chart = AmCharts.makeChart("amchart-graphic", {
            "type": "serial",
            "theme": "light",
            "dataProvider": chartJsonData,
            "legend": {
                "generateFromData": true,
                "position": "right"
            },
            "graphs": [{                
                "fillColorsField": "ColorField",
                "fillAlphas": 0.9,
                "lineAlpha": 0.2,
                "type": "column",
                "valueField": "ValueField",
                "balloonText": "[[BalloonText]]",
                //"labelText": "[[LabelText]]",
                "showAllValueLabels": true
            }],
            "categoryField": "TitleField",
            "depth3D": 50,
            "angle": 30
        });
        var  legendData = chart.dataProvider.map(function (data, idx) {
            var  markerData = {
                "title": data.LegendText,
                "color": data.ColorField,
                "dataIdx": idx
            };            
            return markerData;
        });
        chart.legend.data = legendData;
    }
}

module.exports = {    
    amchartGraphicViewModel: amchartGraphicViewModel
}