'use strict'
require('amcharts3/amcharts/amcharts');
require('ammap3/ammap/ammap.js');
require('amcharts3/amcharts/plugins/responsive/responsive.min.js');
require("amcharts3/amcharts/pie.js");
require("amcharts3/amcharts/serial.js");
require("amcharts3/amcharts/gauge.js");
var ko = (typeof window !== "undefined" ? window['ko'] : typeof global !== "undefined" ? global['ko'] : null)

function numberFormatter(num) {
    if (num > 999999999) {
        return (num / 1000000000).toFixed(2) + ' Billion';
    }
    else if (num > 999999) {
        return (num / 1000000).toFixed(2) + ' Million';
    } 
    else if (num > 999) {
        return (num / 1000).toFixed(2) + ' Thousand';
    } 
    else return num;
}

function recoveryFiltersViewModel(jsonData) {
    var self = this;

    //Constants
    self.AllFilterId = "00000000-0000-0000-0000-000000000000";
    self.showDateInputs = ko.observable(false);
    self.customStartDate = ko.observable();
    self.customEndDate = ko.observable();
    self.errorVisible = ko.observable(false);
    self.ErrorStatus = ko.observable();
    self.buttonEnabled = ko.computed(function () {
        return true;
    });
    var filtersJsonData = JSON.parse(jsonData);

    //ViewModel Data
    self.FiltersData = ko.mapping.fromJS(filtersJsonData);

    // Observables
    self.SelectedDateFilter = ko.observable();
    self.SelectedIndustryFilters = ko.observableArray([]);

    //Computed
    self.SelectedYearFilterLabel = ko.computed(function () {
        if (!!self.SelectedDateFilter()) {
            return self.SelectedDateFilter().filterLabel;
        } else if (!!self.FiltersData && !!self.FiltersData.dateFilters() && self.FiltersData.dateFilters().length > 0) {
            return self.FiltersData.dateFilters()[0].filterLabel;
        } else {
            return '';
        }
    });

    self.SelectedIndustryFiltersLabel = ko.computed(function () {
        if (self.SelectedIndustryFilters().length != 0 && !!self.FiltersData && self.FiltersData.industryFilters().length != self.SelectedIndustryFilters().length) {
            var selectedIndustryNames = [];
            ko.utils.arrayForEach(self.SelectedIndustryFilters(), function (item) {
                selectedIndustryNames.push(item.filterLabel());
            });

            return selectedIndustryNames.join(', ');
        } else {
            return self.FiltersData.allIndustriesFilterLabel();
        }
    });
};

function recoveryStatisticChartsViewModel(option) {
    var self = this;

    var defaults = {
        initialJsonData: null,
        filtersJsonData: null,
        chartIdBase: null,
        legendIdBase: null
    }

    var option = $.extend({}, defaults, option);
    //constants
    self.MobileBreakpoint = 768;

    //Dependent View Model Objects
    self.FiltersVM = new recoveryFiltersViewModel(option.filtersJsonData);

    var chartsJsonData = JSON.parse(option.initialJsonData);

    // Static Objects
    self.SelectedPieChartPieceId = '';
    self.ChartsTabData = ko.mapping.fromJS(chartsJsonData);
    self.ChartIdBase = option.chartIdBase;
    self.LegendIdBase = option.legendIdBase;
    self.IsMobile = false;
    self.IsSearchRunning = false;
    self.IsClearfilter = false;
    self.CurrentChartData = null;
    self.Chart = null;

    if ($(window).width() <= self.MobileBreakpoint) {
        self.IsMobile = true;
    }

    //Observables
    self.CurrentTabId = ko.observable(self.ChartsTabData.tabs()[0].id());

    //Computed Functions
    self.CurrentChartId = ko.computed(function () {
        return self.ChartIdBase + self.CurrentTabId();
    });

    self.CurrentLegendId = ko.computed(function () {
        return self.LegendIdBase + self.CurrentTabId();
    });

    //Functions
    self.TabChange = function (data, evt) {
        if (self.CurrentTabId() != data.id()) {
            self.Chart.clear();
            self.CurrentTabId(data.id());
            self.ResetAmChartSelections();
            self.UpdateAmChartsData();
        }
    };

    self.clearFilters = function(data, evt){
        self.Chart.clear();
        self.CurrentTabId(self.ChartsTabData.tabs()[0].id());
        self.ResetAmChartSelections()
        if(self.FiltersVM.FiltersData.dateFilters().length > 0){
            var item = self.FiltersVM.FiltersData.dateFilters()[0];
            self.FiltersVM.SelectedDateFilter(item)
            self.FiltersVM.SelectedDateFilter().filterValue(item.filterValue())
        }
        self.FiltersVM.customStartDate("")
        self.FiltersVM.customEndDate("")
        self.IsClearfilter = true
        self.UpdateAmChartsData()
    };

    self.customDateSearch = function () {
        if (self.FiltersVM.customStartDate() || self.FiltersVM.customEndDate()) {
            var startTime = Date.parse(self.FiltersVM.customStartDate());
            var endTime = Date.parse(self.FiltersVM.customEndDate());

            if (!isNaN(startTime) && !isNaN(endTime)) {
                self.FiltersVM.errorVisible(false);
                self.UpdateAmChartsData();
            }
            if (!isNaN(startTime) && !endTime) {
                self.FiltersVM.errorVisible(false);
                self.UpdateAmChartsData();

            }
            if (!isNaN(endTime) && !startTime) {
                self.FiltersVM.errorVisible(false);
                self.UpdateAmChartsData();
            }
            return false;
        }
    };

    self.ResetAmChartSelections = function () {
        self.SelectedPieChartPieceId = '';
    };

    self.Update3DSingleColumnChart = function () {
        if (!!self.CurrentChartData) {
            var chartData = self.CurrentChartData.dataColumns;

            if (!!chartData && chartData.length > 0) {
                self.Chart = AmCharts.makeChart(self.CurrentChartId.peek(), {
                    "type": 'serial',
                    "hideCredits" : true,
                    "dataProvider": ko.mapping.toJS(chartData),
                    "valueAxes": [{
                        "stackType": "3d",
                        "unitPosition": "left",
                        "position": "left",
                        "title": self.CurrentChartData.verticalAxisLabel,
                    }],
                    "categoryAxis": {
                        "gridPosition": "start",
                        "title": self.CurrentChartData.horizontalAxisLabel,
                        "labelRotation" : 45
                    },
                    "responsive": {
                        "enabled": true
                    },
                    "startDuration": 1,
                    "usePrefixes": true,
                    "fontFamily": "georgia, serif",
                    "fontSize": 12,
                    "prefixesOfBigNumbers": [{ "number": 1e+3, "prefix": "K" }, { "number": 1e+6, "prefix": "M" }, { "number": 1e+9, "prefix": "B" }, { "number": 1e+12, "prefix": "T" }],
                    "balloon": {
                        "fillAlpha": 1,
                        "verticalPadding": 8,
                        "fontSize": 16,
                        "borderColor": "#cecece"
                    },
                    "categoryField": "columnName",
                    "plotAreaFillAlphas": 0.1,
                    "depth3D": 50,
                    "angle": 30,
                    "graphs": [{
                        "balloonText": "<strong>[[category]]</strong><br />" + self.CurrentChartData.verticalAxisLabel + ": [[columnDisplayValue]]",
                        "fillAlphas": 0.9,
                        "lineAlpha": 0.2,
                        "type": "column",
                        "valueField": "columnValue",
                        "fillColors": self.CurrentChartData.columnColor

                    }],
                });
            }
        }
    };

    self.Update3DTwoColumnChart = function () {
        if (!!self.CurrentChartData) {
            var chartData = self.CurrentChartData.dataColumns;

            self.Chart = AmCharts.makeChart(self.CurrentChartId.peek(),
                {
                    "type": 'serial',
                    "hideCredits" : true,
                    "dataProvider": ko.mapping.toJS(chartData),
                    "valueAxes": [
                        {
                            "stackType": "3d",
                            "unit": self.CurrentChartData.chartUnitPrefix,
                            "unitPosition": "left",
                            "position": "left",
                            "title": self.CurrentChartData.verticalAxisLabel,
                        }
                    ],
                    "categoryAxis": {
                        "gridPosition": "start",
                        "title": self.CurrentChartData.horizontalAxisLabel,
                        "labelRotation": 45
                    },
                    "responsive": {
                        "enabled": true
                    },
                    "startDuration": 1,
                    "usePrefixes": true,
                    "fontFamily": "georgia, serif",
                    "fontSize": 12,
                    "prefixesOfBigNumbers": [
                        { "number": 1e+3, "prefix": "K" }, { "number": 1e+6, "prefix": "M" },
                        { "number": 1e+9, "prefix": "B" }, { "number": 1e+12, "prefix": "T" }
                    ],
                    "balloon": {
                        "fillAlpha": 1,
                        "verticalPadding": 8,
                        "fontSize": 16,
                        "borderColor": "#cecece"
                    },
                    "graphs": [
                        {
                            "balloonText": "<strong>[[category]]</strong><br />" +
                                self.CurrentChartData.columnLegendLabel +
                                ": [[columnDisplayValue]]",
                            "fillAlphas": 0.9,
                            "lineAlpha": 0.2,
                            "title": self.CurrentChartData.columnLegendLabel,
                            "type": "column",
                            "valueField": "columnValue",
                            "fillColors": self.CurrentChartData.columnColor,
                            "legendColor": self.CurrentChartData.columnColor

                        }, {
                            "balloonText": "<strong>[[category]]</strong><br />" +
                                self.CurrentChartData.cumulativeColumnLegendLabel +
                                ": [[cumulativeColumnDisplayValue]]",
                            "fillAlphas": 0.9,
                            "lineAlpha": 0.2,
                            "title": self.CurrentChartData.cumulativeColumnLegendLabel,
                            "type": "column",
                            "valueField": "cumulativeColumnValue",
                            "fillColors": self.CurrentChartData.cumulativeColumnColor,
                            "legendColor": self.CurrentChartData.cumulativeColumnColor
                        }
                    ],
                    "plotAreaFillAlphas": 0.1,
                    "depth3D": 50,
                    "angle": 30,
                    "categoryField": "columnName",
                    "legend": {
                        "position": "top",
                        "align": "center",
                        "autoMargins": false
                    },
                    "export": {
                        "enabled": false
                    }
                });
            AmCharts.checkEmptyData = function (chart) {
                if (0 == chart.dataProvider.length) {
                    // set min/max on the value axis
                    chart.valueAxes[0].minimum = 0;
                    chart.valueAxes[0].maximum = 100;

                    // add dummy data point
                    var dataPoint = {
                        dummyValue: 0
                    };
                    dataPoint[chart.categoryField] = '';
                    chart.dataProvider = [dataPoint];

                    // add label
                    chart.addLabel(0, '50%', 'The chart contains no data', 'center');

                    // set opacity of the chart div
                    chart.chartDiv.style.opacity = 0.5;

                    // redraw it
                    chart.validateNow();
                }
            }
            var barChart = self.Chart;
            AmCharts.checkEmptyData(barChart);
        }
    };

    self.GenerateByIndustryPieChartData = function () {
        var chartsData = [];
        ko.utils.arrayForEach(self.CurrentChartData.chartItems, function (item) {
            if (self.SelectedPieChartPieceId == item.id) {
                ko.utils.arrayForEach(item.subItems, function (subItem) {
                    if (subItem.chartValue > 0) {
                        subItem["pulled"] = true;
                        chartsData.push(subItem);
                    }
                });
            } else {
                if (item.chartValue > 0) {
                    item["pulled"] = false;
                    chartsData.push(item);
                }
            }
        });

        return ko.mapping.toJS(chartsData);
    };

    self.UpdateIndustrySelectablePieChart = function () {
        var labelText = '$[[value]]';
        var legend = {
            "position": "right",
            "align": "center",
            "valueText": "",
            "autoMargins": true,
            "labelWidth": 130
        };

        if (self.IsMobile) {
            labelText = '';
            legend = {
                "position": "top",
                "align": "center",
                "autoMargins": true,
                "maxColumns": 3,
                "labelWidth": 150
            };
        }

        self.Chart = AmCharts.makeChart(self.CurrentChartId.peek(), {
            "type": "pie",
            "hideCredits" : true,
            "dataProvider": self.GenerateByIndustryPieChartData(),
            "balloonText": "<strong>[[title]]</strong><br /> [[chartValueDisplayText]]",
            "fontFamily": "georgia, serif",
            "balloon": {
                "fillAlpha": 1,
                "verticalPadding": 8,
                "fontSize": 12,
                "borderColor": "#cecece"
            },
            "labelText": labelText,
            "titleField": "name",
            "valueField": "chartValue",
            "colorField": "color",
            "outlineColor": "#FFFFFF",
            "outlineAlpha": 0.8,
            "outlineThickness": 2,
            "pulledField": "pulled",
            "prefixesOfBigNumbers": [{ "number": 1e+3, "prefix": "K" }, { "number": 1e+6, "prefix": "M" }, { "number": 1e+9, "prefix": "B" }, { "number": 1e+12, "prefix": "T" }],
            "usePrefixes": true,
            "listeners": [{
                "event": "clickSlice",
                "method": function (event) {
                    var chart = event.chart;
                    if (event.dataItem.dataContext.id != undefined) {
                        self.SelectedPieChartPieceId = event.dataItem.dataContext.id;
                    } else {
                        self.SelectedPieChartPieceId = undefined;
                    }
                    if (self.IsMobile) {
                        self.UpdateAmChartsData();
                        return false;
                    } else {
                        chart.dataProvider = self.GenerateByIndustryPieChartData();
                        chart.validateData();
                        chart.invalidateSize();
                    }
                }
            }],
            "legend": legend,
            "export": {
                "enabled": false
            }
        });
        $(window).on('resize', function () {
            if ((!self.IsMobile && $(window).width() <= self.MobileBreakpoint) || (self.IsMobile && $(window).width() > self.MobileBreakpoint)) {
                self.ResetAmChartSelections();
                self.IsMobile = !self.IsMobile;
                self.UpdateAmChartsData();
            }
        });
    };

    self.UpdateGaugeChart = function () {
        if (!!self.CurrentChartData) {
            var labelsArray = [];

            ko.utils.arrayForEach(self.CurrentChartData.items, function (item) {
                if (!!item.bandLabel) {
                    labelsArray.push(item.bandLabel);
                }
            });
            self.Chart = AmCharts.makeChart(self.CurrentChartId.peek(), {
                "type": "gauge",
                "hideCredits" : true,
                "fontFamily": "georgia, serif",
                "startDuration": 1,
                "balloon": {
                    "fillAlpha": 1,
                    "verticalPadding": 8,
                    "fontSize": 16,
                    "borderColor": "#cecece"
                },
                "responsive": {
                    "enabled": true
                },
                "axes": [{
                    "axisAlpha": 0,
                    "tickAlpha": 0,
                    "labelsEnabled": true,
                    "labelOffset": -30,
                    "startValue": 0,
                    "endValue": self.CurrentChartData.chartEndValue,
                    "startAngle": 0,
                    "endAngle": 250,
                    "bands": ko.mapping.toJS(self.CurrentChartData.items)
                }],
                "allLabels": labelsArray,
                "export": {
                    "enabled": false
                }
            });
        }
    };

    self.UpdateAmChartsData = function () {
        if (!!self.FiltersVM.SelectedDateFilter() && !self.IsSearchRunning) {
            self.IsSearchRunning = true;
            var startDate = self.FiltersVM.customStartDate() && self.FiltersVM.showDateInputs() ? self.FiltersVM.customStartDate() : "";
            var endDate = self.FiltersVM.customEndDate() && self.FiltersVM.showDateInputs() ? self.FiltersVM.customEndDate() : "";
            var dateFilterValue = self.FiltersVM.SelectedDateFilter().filterValue();
            if(self.IsClearfilter){
                if(self.FiltersVM.FiltersData.dateFilters().length > 0){
                    var item = self.FiltersVM.FiltersData.dateFilters()[0]
                    dateFilterValue = item.filterValue()
                    self.IsClearfilter = false
                }
            }
            $.ajax({
                url: '/en/api/blogs/getupdatedchartdata?tabid=' + self.CurrentTabId() + "&datefiltervalue=" + self.FiltersVM.SelectedDateFilter().filterValue() + "&customStartDate=" + startDate + "&customEndDate=" + endDate,
                type: "POST",
                mimeType: 'application/json',
                dataType: 'json',
                cache: false,
                success: function (data) {
                    if (!!data) {
                        self.CurrentChartData = data;
                        switch (data.chartType) {
                            case "TwoColumnDollarAmount":
                            case "TwoColumnTotalRecoveries":
                                self.Update3DTwoColumnChart();
                                break;
                            case "PieChartDollarsByIndustry":
                                self.UpdateIndustrySelectablePieChart();
                                break;
                            case "ChartRecoverySizes":
                                self.Update3DSingleColumnChart();
                                break;
                            case "ChartYearsPending":
                                self.UpdateGaugeChart();
                                break;
                        }
                    }
                    self.IsSearchRunning = false;
                },
                error: function (xhr, textStatus, errorThrown) {
                    self.FiltersVM.errorVisible(true);
                    self.ErrorStatus = xhr.ErrorMessage;
                    console.log(xhr)
                    console.log("textStatus : " + textStatus)
                    console.log("errorThrown : " + errorThrown)
                }
            });
        }
    };
    
    self.FormatChartTitle = function ($data) {
        if (!!$data && $data.chartTitle() && $data.chartTitle().length > 0 && !!self.FiltersVM.SelectedDateFilter()) {
            return $data.chartTitle() + " - " + self.FiltersVM.SelectedDateFilter().filterLabel();
        }
        return '';
    };
};

function recoveryHeatMapViewModel(option) {
    var self = this;

    var defaults = {
        initialJsonData: null,
        filtersJsonData: null,
        mapType: null,
        mapId: null,
    }

    var option = $.extend({}, defaults, option);

    switch (option.mapType) {
        case 'usaCustomLow':
          require("./../lib/ammaps/maps/js/usaCustomLow.js");
            break;
        default:
          require("ammap3/ammap/maps/js/usaLow.js");
    }

    //constants
    self.MobileBreakpoint = 475;
    
    //Dependent View Model Objects
    self.FiltersVM = new recoveryFiltersViewModel(option.filtersJsonData);

    var mapJsonData = JSON.parse(option.initialJsonData);

    // Static Objects
    self.Map = null;
    self.MapData = ko.mapping.fromJS(mapJsonData);
    self.IsSearchRunning = false;
    self.IsMobile = false;

    if ($(window).width() <= self.MobileBreakpoint) {
        self.IsMobile = true;
    }

    // Observables
    self.IsRecoveriesTotalRange = ko.observable(false);
    self.ForceAccordionClosedTrigger = ko.observable(false);
    self.MapOverlayData = ko.observable(null);

    //Computed Functions
    self.CurrentRecoveriesAmountDisplayText = ko.computed(function () {
        if (!!self.MapData) {
            if (self.MapData.currentAmountRecoveriesShown() > 0) {
                return "$" + numberFormatter(self.MapData.currentAmountRecoveriesShown());
            }
        }

        return '';
    });

    self.FormatCircuitModalTotals = ko.computed(function () {
        if (!!self.MapOverlayData()) {
            var amount = 0;
            var totalRecoveries = 0;

            ko.utils.arrayForEach(self.MapOverlayData().circuitData.recoveriesByIndustry, function (item) {
                amount += item.totalAmount;
                totalRecoveries += item.totalRecoveries
            });

            return "$" + numberFormatter(amount) + " / " + totalRecoveries + " Recoveries";
        }

        return '';
    });

    self.AlaskaExternalTile = ko.computed(function () {
        if (self.MapData.mapStateTerritories().length > 0) {
            var mapItem = ko.utils.arrayFirst(self.MapData.mapStateTerritories(), function (item) {
                return item.id() == "US-AK";
            });
            return mapItem;
        }
        return null;
    });

    self.HawaiiExternalTile = ko.computed(function () {
        if (self.MapData.mapStateTerritories().length > 0) {
            var mapItem = ko.utils.arrayFirst(self.MapData.mapStateTerritories(), function (item) {
                return item.id() == "US-HI";
            });
            return mapItem;
        }
        return null;
    });

    self.VirginIslandsExternalTile = ko.computed(function () {
        if (self.MapData.mapStateTerritories().length > 0) {
            var mapItem = ko.utils.arrayFirst(self.MapData.mapStateTerritories(), function (item) {
                return item.id() == "VI";
            });
            return mapItem;
        }
        return null;
    });

    self.PuertoRicoExternalTile = ko.computed(function () {
        if (self.MapData.mapStateTerritories().length > 0) {
            var mapItem = ko.utils.arrayFirst(self.MapData.mapStateTerritories(), function (item) {
                return item.id() == "PR";
            });
            return mapItem;
        }
        return null;
    });

    self.DCExternalTile = ko.computed(function () {
        if (self.MapData.mapStateTerritories().length > 0) {
            var mapItem = ko.utils.arrayFirst(self.MapData.mapStateTerritories(), function (item) {
                return item.id() == "US-DC";
            });
            return mapItem;
        }
        return null;
    });

    self.FederalExternalTile = ko.computed(function () {
        if (self.MapData.mapStateTerritories().length > 0) {
            var mapItem = ko.utils.arrayFirst(self.MapData.mapStateTerritories(), function (item) {
                return item.id() == "FED";
            });
            return mapItem;
        }
        return null;
    });

    self.ResetAmChartSelections = function () {
        $('.recovery-map-overlay').fadeOut();
        self.MapOverlayData(null);

        if (!!self.Map) {
            ko.utils.arrayForEach(self.Map.dataProvider.areas, function (item) {
                item.showAsSelected = false;
            });
            self.Map.validateData();
        }
        $('.range-item').removeClass('active-hover');
        $(".external-location").removeClass('hover-selected');
        $('.external-location').removeClass('selected');
    };

    self.UpdateAmChartsData = function () {
        var usedCircuits = [];
        var totalRecoveries = 0;
        var totalAmount = 0;

        if (!self.IsSearchRunning) {
            self.IsSearchRunning = true;
            if (!!self.Map) {
                if (!!self.FiltersVM.SelectedDateFilter() && !!self.MapData) {
                    var viewModelToSend = ko.mapping.toJS(self.MapData);
                    viewModelToSend.mapStateTerritories = [];
                    viewModelToSend.mapRecoveryAmountRangeData.isActive = !self.IsRecoveriesTotalRange();
                    viewModelToSend.mapTotalRecoveriesRangeData.isActive = self.IsRecoveriesTotalRange();
                    viewModelToSend.currentYearFilter = self.FiltersVM.SelectedDateFilter().filterValue();
                    viewModelToSend.currentFilterIds = [];
                    if (self.FiltersVM.SelectedIndustryFilters().length > 0 && self.FiltersVM.SelectedIndustryFilters().length < self.FiltersVM.FiltersData.industryFilters().length) {
                        ko.utils.arrayForEach(self.FiltersVM.SelectedIndustryFilters(), function (item) {
                            viewModelToSend.currentFilterIds.push(item.id());
                        });
                    }
                    if (self.FiltersVM.customStartDate()) {
                        viewModelToSend.customStartDate = self.FiltersVM.customStartDate();
                    }
                    if (self.FiltersVM.customEndDate()) {
                        viewModelToSend.customEndDate = self.FiltersVM.customEndDate();
                    }
                    if(self.IsClearfilter){
                        if(self.FiltersVM.FiltersData.dateFilters().length > 0){
                            var item = self.FiltersVM.FiltersData.dateFilters()[0]
                            viewModelToSend.currentYearFilter = item.filterValue()
                            self.IsClearfilter = false
                        }
                    }
                    $.ajax({
                        url: '/en/api/blogs/getupdatedmapdata',
                        type: "POST",
                        dataType: "json",
                        contentType: "application/json",
                        data: ko.mapping.toJSON(viewModelToSend),
                        cache: false,
                        success: function (data) {
                            var newKoData = ko.mapping.fromJS(data);
                            self.MapData.mapStateTerritories(newKoData.mapStateTerritories());
                            self.MapData.currentTotalRecoveriesShown(newKoData.currentTotalRecoveriesShown());
                            self.MapData.currentAmountRecoveriesShown(newKoData.currentAmountRecoveriesShown());
                            self.Map.dataProvider.areas = data.mapStateTerritories;
                            self.Map.validateData();
                            self.IsSearchRunning = false;
                        },
                        error: function (xhr, textStatus, errorThrown) {
                            console.log(xhr)
                            console.log("textStatus : " + textStatus)
                            console.log("errorThrown : " + errorThrown)
                        }
                    });
                }
            } else {
                self.GenerateNewMap();
                setTimeout(function () {
                    self.IsSearchRunning = false;
                }, 100);
            }
        }
        
    };

    self.GenerateNewMap = function () {
        self.Map = AmCharts.makeChart(option.mapId, {
            "type": "map",
            "hideCredits" : true,
            "dataProvider": {
                "map": option.mapType,
                "areas": ko.mapping.toJS(self.MapData.mapStateTerritories())
            },
            "fontFamily": "georgia, serif",
            "areasSettings": {
                "autoZoom": false,
                "bringForwardOnHover": true,
                "rollOverOutlineColor": '#000',
                "rollOverOutlineThickness": 2,
                "selectable": true,
                "selectedOutlineColor": '#000',
                "descriptionWindowWidth": 600,
                "selectedOutlineThickness": 3,
                "descriptionWindowTop": 100,
                "descriptionWindowLeft": 200
            },
            "balloon": {
                "fillAlpha": 1,
                "verticalPadding": 8,
                "fontSize": 16,
                "borderColor": "#cecece"
            },
            "export": {
                "enabled": false
            },
            "zoomControl": {
                "zoomControlEnabled": false,
                "homeButtonEnabled": false
            },
            "listeners": [{
                "event": "rendered",
                "method": function (e) {
                    // Let's log initial zoom settings (for home button)
                    self.Map.initialZoomLevel = self.Map.zoomLevel();
                    self.Map.initialZoomLatitude = self.Map.zoomLatitude();
                    self.Map.initialZoomLongitude = self.Map.zoomLongitude();
                }
            }]

        });

        self.AddMapEvents();
    };

    self.EnableCircuitHover = function (data) {
        if (!!data) {
            var groupId = data.groupId();
            var groupObject = self.GetFirstMapObjectInGroup(groupId, true);
            if (!!groupObject) {
                self.Map.rollOverMapObject(groupObject);
                
            }
        }
    };

    self.DisableCircuitHover = function (data) {
        if (!!data) {
            var groupId = data.groupId();
            var groupObject = self.GetFirstMapObjectInGroup(groupId, true);
            if (!!groupObject) {
                self.Map.rollOutMapObject(groupObject);

            }
        }
    };

    self.NotExternalItem = function (id) {
        return id != self.AlaskaExternalTile().id() && id != self.HawaiiExternalTile().id() && id != self.PuertoRicoExternalTile().id() && id != self.VirginIslandsExternalTile().id() && id != self.DCExternalTile().id() && id != self.FederalExternalTile().id();

    };

    self.GetFirstMapObjectInGroup = function (groupId, includeExternalItems) {
        if (!!groupId && !!self.Map) {
            var groupObject = ko.utils.arrayFirst(self.Map.dataProvider.areas, function (item) {
                return groupId == item.groupId && (self.NotExternalItem(item.id) || includeExternalItems);
            });

            return groupObject;
        } else {
            return null;
        }
    };

    self.CloseMapOverlay = function (data, evt) {
        evt.preventDefault();
        self.ResetAmChartSelections();
    };

    self.ZoomInMap = function () {
        self.Map.zoomIn();
    };

    self.ZoomOutMap = function () {
        self.Map.zoomOut();
    };

    self.clearFilters = function(data, evt){
        self.IsClearfilter = true
        self.FiltersVM.SelectedIndustryFilters([])
        self.FiltersVM.customStartDate("")
        self.FiltersVM.customEndDate("")
        self.IsRecoveriesTotalRange(false)
        if(self.FiltersVM.FiltersData.dateFilters().length > 0){
            var item = self.FiltersVM.FiltersData.dateFilters()[0];
            self.FiltersVM.SelectedDateFilter(item)
            self.FiltersVM.SelectedDateFilter().filterValue(item.filterValue())
        }
        self.UpdateAmChartsData()
    };

    self.customDateSearch = function () {
        self.UpdateAmChartsData();

    };

    self.CenterMap = function () {
        self.Map.zoomToLongLat(self.Map.initialZoomLevel, self.Map.initialZoomLongitude, self.Map.initialZoomLatitude);
        self.ResetAmChartSelections();
    };

    self.TriggerMapOverlay = function () {
        if (!!self.MapOverlayData()) {
            var valueFieldName = self.IsRecoveriesTotalRange() ? "totalRecoveries" : "totalAmount";
            var ballonTextValueField = self.IsRecoveriesTotalRange() ? "[[value]]" : "[[totalAmountDisplayText]]";
            var legend = {
                "position": "right",
                "autoMargins": false,
                "valueText": "",
                "labelWidth": 150,
                "marginLeft": 0
            };

            if (self.IsMobile) {
                legend = {
                    "position": "top",
                    "autoMargins": false,
                    "valueText": ""
                }
            }

            var chart = AmCharts.makeChart("mapOverlayChart", {
                "type": "pie",
                "hideCredits" : true,
                "labelsEnabled": false,
                "dataProvider": ko.mapping.toJS(self.MapOverlayData().circuitData.recoveriesByIndustry),
                "valueField": valueFieldName,
                "titleField": "name",
                "colorField": "color",
                "fontFamily": "georgia, serif",
                "balloonText": "<strong>[[title]]</strong><br />" + ballonTextValueField + " ([[percents]]%)",
                "balloon": {
                    "fillAlpha": 1,
                    "verticalPadding": 8,
                    "fontSize": 12,
                    "borderColor": "#cecece",
                    "fixedPosition": true
                },
                "legend": legend,
                "export": {
                    "enabled": false
                }
            });

            $('.recovery-map-overlay').fadeIn();
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".recovery-map-components").offset().top - 150
            }, 500);
        }
    };

    self.ExternalTileClick = function (data, evt) {
        var groupObject = self.GetFirstMapObjectInGroup(data.groupId(), false);
        if (!!groupObject) {
            self.Map.selectObject(groupObject);
        }

        if ($(evt.target).hasClass('external-location')) {
            $(evt.target).addClass('selected');
        } else {
            $(evt.target).parents('.external-location').addClass('selected');
        }

        self.MapOverlayData(ko.mapping.toJS(data));
        self.TriggerMapOverlay();
    };

    self.AddMapEvents = function () {
        self.Map.addListener('clickMapObject', function (event) {
            self.MapOverlayData(event.mapObject);
            $(".external-location").removeClass('selected');
            $(".external-location[data-groupid='" + event.mapObject.groupId + "']").addClass('selected');
            self.TriggerMapOverlay();
        });

        self.Map.addListener('rollOverMapObject', function (event) {
            $('#' + event.mapObject.rangeId).addClass('active-hover');
            $(".external-location").removeClass('hover-selected');
            $(".external-location[data-groupid='" + event.mapObject.groupId + "']").addClass('hover-selected');
        });

        self.Map.addListener('rollOutMapObject', function (event) {
            $('#' + event.mapObject.rangeId).removeClass('active-hover');
            $(".external-location").removeClass('hover-selected');
        });

        $(window).on('resize', function () {
            if ((!self.IsMobile && $(window).width() <= self.MobileBreakpoint) || (self.IsMobile && $(window).width() > self.MobileBreakpoint)) {
                self.ResetAmChartSelections();
                self.IsMobile = !self.IsMobile;
            }
        });
    };
}

ko.bindingHandlers.OnFilterChange = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    },
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var selectedFilters = ko.utils.unwrapObservable(valueAccessor());
        bindingContext.$root.ResetAmChartSelections();
        var selectedID = (typeof selectedFilters.id === "function") ? selectedFilters.id() : "";
        if (selectedID !== "00000000-0000-0000-0000-000000000000" && element.className === "filter-dropdown") {
            if (bindingContext.$root.FiltersVM){
                bindingContext.$root.FiltersVM.showDateInputs(false);
            }
            bindingContext.$root.UpdateAmChartsData();
        } else if (element.className === "filter-dropdown") {
            if (bindingContext.$root.FiltersVM) {
                bindingContext.$root.FiltersVM.showDateInputs(true);
            }
        }
        else {
            bindingContext.$root.UpdateAmChartsData();
        }
    }
};

ko.bindingHandlers.ToggleAccordion = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        $(element).on('click', function () {
            $(this).toggleClass('active');
        });
        $(":checkbox").on('change', function () {
            if ($(element).hasClass('active')) {
                $(element).toggleClass('active');
            }
        });
        $('select').on('change', function () {
            if ($(element).hasClass('active')) {
                $(element).toggleClass('active');
            }
        });
    },
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    }
};

module.exports = {
    recoveryStatisticChartsViewModel: recoveryStatisticChartsViewModel,
    recoveryHeatMapViewModel: recoveryHeatMapViewModel
}
