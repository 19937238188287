(function($){
'use strict';

    //On Ready
    $(function () { 
        var $historySection = $('.history-blocks-list');

        //Do we have this element on the page?? If so lets fire some events
        if ($historySection.length > 0) {

            //find all elements 
            var $historyBlocks = $historySection.find('.history-block'),
                $historyDiamonds = $historySection.find('.history-diamond'),
                $historyLine = $historySection.find('.js-history-line'),
                $historyDates = $('.js-history-dates'),
                $historyDatesSticky = $historyDates.find('.js-history-dates-sticky'),
                $historyDatesBtn = $historyDates.find('.history-date-link'),
                $htmlbody = $('html, body'),
                documentOffset, 
                historyBlocksData,
                historyBlocksLength = $historyBlocks.length,
                historySectionOffset,
                historyStickyOffset,
                headerHeight,
                subNavHeight,
                windowOffset,
                windowHeight, 
                maxHeightLine,
                headerElOffset,
                mobileState;


            $(window).on('load', function() {
                historyInit();
            });
        }

        function calculateOffset () {

            //Value 
            var headerHeight = $('.site-header').outerHeight() * 2, // account for the top percentage offset
                subNavHeight = $('.site-subnav').outerHeight() || 0;
            
            headerElOffset = headerHeight + subNavHeight + 100;

            historyStickyOffset = $historyDates.offset().top - $('.site-header').outerHeight() - subNavHeight;

            historySectionOffset =  $historySection.offset().top - headerElOffset + 100; 

            windowHeight = window.innerHeight;
            documentOffset  = $(document).height() - windowHeight - (windowHeight * .1) ;  

            documentOffset  = Math.floor(documentOffset)

            maxHeightLine = $historyBlocks.eq($historyBlocks.length - 1).offset().top - headerElOffset;

            $historyDates.css('height', $historyDates.height() + 'px');
            $historyDatesSticky.css('max-width', $historyDates.width() + 'px');

            cacheOffsets();



            return historySectionOffset;
        }

        function cacheOffsets() {
            historyBlocksData = [];

            for (var i=0; i < historyBlocksLength; i++ ) {
                historyBlocksData.push({
                    el: $historyBlocks.eq(i),
                    offset: $historyBlocks.eq(i).offset().top - headerElOffset,
                });
            }
        }

        function historyInit() {

            calculateOffset();

            historyDateTriggers();

            windowListener.onScroll(animateLineLogic());
            windowListener.onScroll(animateTriangle());
            windowListener.onScroll(stickyHistory());


            windowResizeHandler();

        }

        function animateLineLogic(windowOffset) {

            return function(windowOffset) {

                if (windowOffset > historySectionOffset) {
                    $historyDates.addClass('is-sticky');

                    if ( windowOffset > maxHeightLine) {
                        return false;
                    } 

                    if(windowOffset > documentOffset) {
                        historyBlocksData[historyBlocksData.length - 1].el.addClass('animate-triangle'); 

                        $historyLine.css('height', 153 +  maxHeightLine - historySectionOffset);
                    }

                    else {
                        $historyLine.css('height', 153 +  windowOffset - historySectionOffset);
                    }

                    
                }

                else {
                    $historyDates.removeClass('is-sticky');
                }
            }
        }

        function stickyHistory() {

            return function(windowOffset) {
                if (windowOffset > historyStickyOffset) {
                    $historyDates.addClass('is-sticky');
                }

                else {
                    $historyDates.removeClass('is-sticky');
                }
            }
        }

        var animateTriangle = function() {

            return function(windowOffset){

                $historyBlocks.each(function(ndx) {
                    if ( windowOffset > historyBlocksData[ndx].offset ) {    
                        $(this).addClass('animate-triangle');
                        if(ndx === $historyBlocks.length - 1)
                            animateTriangle = function(){};//NOP
                    }
                });

            }
        };

        function historyDateTriggers() {

            $historyDatesBtn.on('click.dates', function(ev){
                ev.preventDefault();
                
                var _self = $(this),
                    clickedIndex = $historyDatesBtn.index(_self);



                $htmlbody.animate({
                    scrollTop: historyBlocksData[clickedIndex].offset + 25
                }, 700);

            });
        }

        function windowResizeHandler() {
            var resizeTimer;
            
            $(window).on('resize.historyDates', function(){

                clearTimeout(resizeTimer);

                resizeTimer = setTimeout(function(){
                    calculateOffset();

                }, 700);
            });
        }
    });

})(jQuery);