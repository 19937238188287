(function ($) {
    'use strict';

    //On DOM Ready
    $(function () {
        var $subNav = $('.js-subnav');
        var $search = $('.header-nav-menu');

        //Do we have this element on the page?? If so lets fire some events
        if ($subNav.length > 0) {
            var $subNavTrigger = $subNav.find('.site-subnav-label'),
                $subNavLinks = $subNav.find('.site-subnav-link');

            subNavInit();
        }

        function subNavMobileToggle() {
            $subNavTrigger.on('click.subNav', function () {

                $subNav.toggleClass('is-expanded');

                if ($subNav.hasClass('is-expanded')) {
                    $search.hide();
                } else {
                    $search.show();
                }

            });
        }

        function subNavInit() {
            var activeNavText = $subNavLinks.filter('.is-active').text();

            $subNavTrigger.text(activeNavText);

            subNavMobileToggle();
        }

    });
})(jQuery);