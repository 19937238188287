jQuery(document).ready(function ($) {
    var delay = parseInt($("#homeHero").attr("data-slide-delay"), 10);
    var slides = $('.hero-slide')
    if (!delay || delay < 2000) {
        delay = 2000;
    }

    function recur(i) {
        if (i === slides.length) {
            i = 0;
        }
        var $slide = $(slides[i])
        $slide.removeClass('active')
        $slide.addClass('active')
        setTimeout(function () {
            $slide.removeClass('active')
            recur(++i)

        }, delay)

    }

    recur(0)




});