(function($) {
    'use strict';

    $(document).ready(function bindListAccordianEventHandlers() {
            if (window.location.hash) {
                var hash = window.location.hash;
                handleAnchorLinks(hash);
            } 
            var $accordianItemContainer,
                pageHasListAccordian;

            $accordianItemContainer = $('.accordian-item-container');
            pageHasListAccordian = $accordianItemContainer.length > 0;

            if (pageHasListAccordian) {
                $accordianItemContainer.click(handleAccordianItemClick);
                console.log('bind');
            }

            function handleAccordianItemClick() {
                var $thisContainer,
                    $thisContainersRichText;

                $thisContainer = $(this);
                $thisContainersRichText = $thisContainer.closest('.accordian-item')
                    .find('.accordian-rich-text');

                $thisContainer.toggleClass('active');
                $thisContainersRichText.slideToggle();
            }
            
            //Accessibility: Make items on page accessible by keyboard-only users
            function makeKeyboardAccessible (item) {
                item.each(function () {
                    $(this)[0].addEventListener('keydown', e => {
                        const keyDown = e.key !== undefined ? e.key : e.keyCode;
                        if ( (keyDown === 'Enter' || keyDown === 13) || (['Spacebar', ' '].indexOf(keyDown) >= 0 || keyDown === 32)) {
                            // (prevent default so the page doesn't scroll when pressing space)
                            e.preventDefault();
                            $(this)[0].click();
                        }
                    });
                }); 
            }
            makeKeyboardAccessible($(".section-link"));

            $("a").click(function() {
                var hash = $(this).prop("hash");
                if (hash) {
                    handleAnchorLinks(hash);
                }
            });
            function handleAnchorLinks(hash) {
                hash = hash.replace(/[^\w\s]/gi, '').toLowerCase()
                var anchorElement = $("#"+hash);
                if (anchorElement.length > 0) {
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $(anchorElement).offset().top - 200
                    }, 2000);
                    var $thisContainer,
                        $thisContainersRichText;

                    $thisContainer = anchorElement;
                    $thisContainersRichText = $thisContainer.closest('.accordian-item')
                        .find('.accordian-rich-text');

                    $thisContainer.toggleClass('active');
                    $thisContainersRichText.slideToggle();
                }
                
            }
        }
    );
})($);