'use strict';

// Service that handles on scroll events leveraging requestAnimationFrame when available 
var windowListener = window.windowListener = {};



windowListener.properties = {

    /**
     * Contains all registered callbacks
     * @type {Array}
     */
    events: [],


    /**
     * Tracks if requestAnimationFrame has been called
     * http://www.html5rocks.com/en/tutorials/speed/animations/
     * @type {Boolean}
     */
   
    rAFTicker: false

};

//Method to Initialize  Listener
windowListener.bindEvents = function () {

    $(window).on('scroll.windowListener', function(){

        windowListener.requestTicker();

    });
};

/**
 * Calls rAF if it's not already
 * been done already
 */

windowListener.requestTicker = function () {

    if(!windowListener.properties.rAFTicker) {

        requestAnimationFrame(windowListener.onClientSizeChange);

        windowListener.properties.rAFTicker = true;
    }
};


/**
 * Runs against callback events
 * @returns {undefined}
 */
windowListener.onClientSizeChange = function () {
    
    var windowOffset = window.pageYOffset;
    
    //console.log(windowListener.properties.events.length);
    
    if ( windowListener.properties.events.length) {

        windowListener.properties.events.forEach(function (event) {
           
            event(windowOffset);
        });

    }

    else {
        //remove the on scroll event if no events are registered
        $(window).off('scroll.windowListener');
    }
    
    // allow further rAFs to be called
    windowListener.properties.rAFTicker = false; 
};


    
/**
 * Adds Callback 
 * @param {Function} callback
 * @returns {undefined}
 */
windowListener.onScroll = function (callback) {
    windowListener.properties.events.push(callback);
};

//bind events
$(window).on('load', function() {
    windowListener.bindEvents();
});

