(function($, window){
    //On Ready
	$(function () {
        var $splash = $('#splash');
        var $close = $splash.find('.close');
        var $continue = $splash.find('.continue');
        var $links = $splash.find('a');
        var $body = $('body');

        var cookieKey = 'splash-cookie';

        function Init() {
            if (!($splash.length)) {
                return;
            }
            $close.click(function() {
                //close splash overlay
                CloseOverlay();
                //set cookie
                SetCookie();
            });
            $continue.click(function() {
                //close splash overlay
                CloseOverlay();
                //set cookie
                SetCookie();
            });
            $links.each(function() {
                $(this).click(function() {
                    CloseOverlay();
                    SetCookie();
                });
            });
            var cookie = GetCookie();
            if (!cookie || cookie == '') {
                ShowOverlay();
            }
        }

        function CloseOverlay() {
            $splash.removeClass('overlay-all');
            $splash.removeClass('is-shown-overlay');
            $splash.removeClass('is-shown-splash');
            $body.removeClass('no-scroll');
        }

        function ShowOverlay() {
            $splash.addClass('overlay-all');
            $splash.addClass('is-shown-overlay');
            $splash.addClass('is-shown-splash');
            $body.addClass('no-scroll');
        }

        function GetCookie() {
            return Cookies.get(cookieKey);
        }

        function SetCookie() {
          Cookies.set(cookieKey, '1', { expires: 1000, path: "/" });
        }

        Init();
	});
})(jQuery, window);