'use strict'

function Accordion($accordionWrapper, $customTriggers, $customContent) {
    this.options = {
        expandFirst: $accordionWrapper.data('accordion-first-open') || false,
        nodeExclusive: ( $accordionWrapper.data('accordion-exlusive') ) ?  true : false
    }
    this.wrapper = $accordionWrapper;
    this.triggers = $customTriggers ||  $accordionWrapper.find('.js-accordion-trigger') ;
    this.contents = $customContent || $accordionWrapper.find('.js-accordion-content') ;
    this.clickedIndex = 0;
    this.counter = 0;

    this.bindEvents();
}

Accordion.prototype.closeAll = function() {
    this.triggers.removeClass('is-active-menu');
    this.contents.slideUp();


}

Accordion.prototype.openAccordionNode = function($activeAccordion, clickedIndex){
    $activeAccordion.addClass('is-active-menu');
    this.contents.eq(clickedIndex).slideDown();
}

Accordion.prototype.closeAccordionNode = function($accordionNode, clickedIndex){
    $accordionNode.removeClass('is-active-menu');
    this.contents.eq(clickedIndex).slideUp();
}


Accordion.prototype.bindEvents = function() {
    var _self = this,
        nodeExclusive = this.options.nodeExclusive;

    
    this.triggers.on('click.Accordion', function(){

        var $currentAccordion = $(this);

        _self.clickedIndex = _self.triggers.index($currentAccordion);

        if ( $currentAccordion.hasClass('is-active-menu') ) {

            (nodeExclusive) ? _self.closeAll() : _self.closeAccordionNode($currentAccordion, _self.clickedIndex); 
            
        }   

        else {
            //MultiLIne Ternary Syntax, Pay close attention to the comma
            (nodeExclusive) ? (
                _self.closeAll(),
                _self.openAccordionNode($currentAccordion, _self.clickedIndex)
            ) : (

                _self.openAccordionNode($currentAccordion, _self.clickedIndex)
            );
        }

    });


    //Display is controlled through CSS, I'm updating the Accordion State at this point. 
    if (this.options.expandFirst) {
        _self.triggers.eq(0).addClass('is-active-menu');
        _self.contents.eq(0).css('display', 'block');
        _self.wrapper.attr('data-accordion-first-open', 'false')

    }
}

function initalizeAccordians() {
    var $sectionAccordion = $('.js-accordion');

    //if we have an accordion on the page create new accordion instance
    if ($sectionAccordion) {
        var pageAccordion = new Accordion($sectionAccordion);
    }
}

window.initalizeAccordians = initalizeAccordians;
window.closeAllAccordions = Accordion.closeAll 



$(function () {

    initalizeAccordians();

});