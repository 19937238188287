'use strict';

$(function () { 
    var $alphaCarousel = $('.js-alpha-carousel');

    if ($alphaCarousel.length > 0) {
        var windowWidth = window.innerWidth,
            mobileState = false,
            $carouselAlpha = $alphaCarousel.find('.list-alpha'),
            $carouselBelt = $alphaCarousel.find('.list-alpha-belt'),
            $carouselArrowLeft = $alphaCarousel.find('.js-arrow-left'),
            $carouselArrowRight = $alphaCarousel.find('.js-arrow-right'),
            carouselViewport,
            carouselLength,
            carouselLengthMax,
            carouselDelta, 
            carouselIndex = 0;

       
        alphaCarouselInit();
    }


    function setupCarousel () {
       
        carouselViewport  = $carouselBelt.width();
        carouselIndex = 0;

        if ( windowWidth < 380) {

            carouselLength = 8;
            carouselLengthMax = carouselLength - 1;
            //alpha length divided by number of viewable letters
            carouselDelta = 8.666667;

        }

        else if ( windowWidth >= 380 &&  windowWidth < 440) {

            carouselLength = 6;
            carouselLengthMax = carouselLength - 1;
            //alpha length divided by number of viewable letters
            carouselDelta = 6.5;

        }

        else {
            carouselLength = 5;
            carouselLengthMax = carouselLength - 1;
            //alpha length divided by number of viewable letters
            carouselDelta = 5.2;
        }
        
        //setup width
        $carouselAlpha.css('width', ( carouselViewport * carouselDelta ) + 'px' );

    }

    function resetCarouselStyles() {

        $carouselAlpha.css({
            'width': 'auto',
            'margin-left': 0
        });
    }


    function carouselLogic(direction) {

        if (direction === 'next') {
            ++carouselIndex;

            if (carouselIndex > carouselLength) carouselIndex = 0;
        }
        else if (direction === 'prev') { 
            --carouselIndex;

            if (carouselIndex < 0 ) carouselIndex = carouselLength;
        }

        $carouselAlpha.css('margin-left', -(carouselIndex * carouselViewport) + 'px' )

    }


    function alphaCarouselInit() {

        //Setup variables
        if (windowWidth <= 600) {
            setupCarousel();

            mobileState = true;
        }

       

        //bind Events
        $carouselArrowLeft.on('click', function(ev) {
            ev.preventDefault();
            carouselLogic('prev');
        });

        $carouselArrowRight.on('click', function(ev) {
            ev.preventDefault();
            carouselLogic('next');
        });

        //setup windowsize event handler
        windowResizeHandler();
    }


    function windowResizeHandler() {
        var resizeTimer;
        
        $(window).on('resize.carouselAlpha', function(){

            clearTimeout(resizeTimer);

            resizeTimer = setTimeout(function(){
                windowWidth = window.innerWidth;

                 if (windowWidth <= 600) {
                    mobileState = true;
                    setupCarousel();
                }
                else {
                    mobileState = false;
                    resetCarouselStyles();
                }
            }, 500);
        });
    }

});