// Each row has 3 columns. We need to go through each tile to see if there are any
// empty 3rd columns due to having extra wide cards (cards with the "card-grid__card--md-h" class)
// appearing one right after another
var updateMasonryPositions = function ($masonry) {
  var currentRow = 1
  var currentRowFill = 0
  var rowsNeedingFiller = []

  $masonry.find('.card-grid__card').each(function (index, item) {
      var $item = $(item)
      var isWideCard = $item.hasClass('card-grid__card--md-h') ||
        $item.hasClass('card-grid__card--md-h-full-overlay') ||
        $item.hasClass('card-grid__card--lg')

      if (isWideCard) {
          // If current row already has 2 columns full, the wide card will
          // be forced to the next row which results in a gap in the 3rd column.
          // We keep track of the row that has a gap in the 3rd column so that we
          // can fill it later with a normal width card
          if (currentRowFill === 2) {
              rowsNeedingFiller.push(currentRow)
              currentRow++
              currentRowFill = 2
          // If current row already has 1 column full, this wide card will fill remaining
          // column.
          } else if (currentRowFill === 1) {
              currentRow++
              currentRowFill = 0
          } else {
              currentRowFill = 2
          }
      } else {
          // These are normal width cards. First we check to see if we need to fill any gaps.
          // If so, we fill the gap by setting custom CSS variables that are used in _card-grid.scss
          if (rowsNeedingFiller.length) {
              item.style.setProperty('--card-grid__card-column-start', 3)
              item.style.setProperty('--card-grid__card-row-start', rowsNeedingFiller.shift())
              return
          } else {
              if (currentRowFill === 2) {
                  currentRow++
                  currentRowFill = 0
              } else {
                  currentRowFill++
              }
          }
      }
  })
}


module.exports = updateMasonryPositions