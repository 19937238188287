
var ko = (typeof window !== "undefined" ? window['ko'] : typeof global !== "undefined" ? global['ko'] : null)

var arrowKeyMap = {
    38: 'up',
    40: 'down'
}

function Typeahead(url, inputSelector, typeaheadSelector, viewModel, addSearchLink) {
    if (!!url && !!inputSelector) {
        this.url = url
        this.input = $(inputSelector)
        this.typeahead = $(typeaheadSelector)
        this.currentRequest = null
        this.cache = new Object
        this.viewModel = viewModel || {}
        this.viewModel.typeaheadData = ko.observableArray()
        this.viewModel.hasSearchLink = ko.observable(!!addSearchLink)
        this.viewModel.searchText = ko.observable('')
        this.input.on('keydown', handleKeyDown.bind(this))
        this.input.on('keyup', handleKeyup.bind(this))
        this.input.on('focus', handleFocus.bind(this))
        this.input.on('blur', handleBlur.bind(this))
        this.typeahead.on('click.typeahead', function (e) { e.stopPropagation() })
        this.typeahead.on('transitionend', function (e) { e.stopPropagation() })
        if (!!!viewModel) { //is this is being initialized by another js module using knockout?
            ko.applyBindings(this.viewModel, this.input.closest('.js-typeahead').get(0))
        }
    }
}


function handleKeyDown(e) {
    if (e.keyCode == 13 && this.typeahead.find('.selected').length > 0) {
        e.preventDefault()
    }
}

function handleKeyup(e) {
    e.preventDefault()
    var direction = arrowKeyMap[e.keyCode]
    if (!!!direction) {
        if (e.keyCode == 13) {
            var selected = this.typeahead.find('.selected a')
            if (selected.length > 0) {
                window.location.href = selected.attr('href')
            }
            this.typeahead.addClass('closed')
        } else {
            var val = this.input.val()
            this.viewModel.searchText(val)
            if (!!val && val.length >= 3 && !!!this.cache[val]) {
                if (!!this.currentRequest) {
                    this.currentRequest.abort()
                }
                this.currentRequest = this.search(val)
            } else if (!!this.cache[val]) {
                handleData.call(this, this.cache[val])
            } else if (val.length == 0) {
                this.typeahead.addClass('closed')
                this.viewModel.typeaheadData([])
            }
        }
    } else {
        var typeaheadList = this.typeahead.find('ul')
        var selected = typeaheadList.children('.selected')
        var lastIndex = parseInt(typeaheadList.children('li:last-child').data('index'))
        if (selected.length == 0) {
            if (direction == 'down') {
                this.typeahead.find('ul li:first-child').addClass('selected')
                this.typeahead.find('ul li:first-child').find('a').eq(0).focus()
            } else {
                this.typeahead.find('ul li:last-child').addClass('selected')
                this.typeahead.find('ul li:last-child').find('a').eq(0).focus()
            }
        } else {
            var index = parseInt(selected.data('index'))
            typeaheadList.children('li').removeClass('selected')
            if (direction == 'down') {
                if (++index > lastIndex) {
                    index = 0;
                }
            } else {
                if (--index < 0) {
                    index = lastIndex
                }
            }
            typeaheadList.children('li[data-index="' + index + '"]').addClass('selected')
            typeaheadList.children('li[data-index="' + index + '"]').find('a').eq(0).focus()
        }
    }
}

function handleFocus(e) {    
    e.preventDefault();
    if (!!this.viewModel.typeaheadData && this.viewModel.typeaheadData().length > 0) {
        this.typeahead.removeClass('closed');
        this.typeahead.blur();
    }
}

function handleBlur(e) {
    e.preventDefault();

    var _this = this,
        $body = $('body');

    $body.on('click.typeaheadEvent', function(ev){
        _this.typeahead.addClass('closed');
        $body.off('click.typeaheadEvent');
    });
}

Typeahead.prototype.search = function (keyword) {    
    var self = this
    return $.ajax({
        url: self.url,
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({ keyword: keyword }),
        cache: false,
        success: handleData.bind(self),
        error: handleError.bind(self)
    })
}

function handleData(data) {
    this.viewModel.typeaheadData.removeAll()
    if (!!data && data.length > 0) {
        data.forEach(function (item) {
            this.viewModel.typeaheadData.push(item)
        }.bind(this))
        this.typeahead.removeClass('closed')
    } else {
        this.typeahead.addClass('closed')
    }
    this.cache[this.input.val()] = data
    this.currentRequest = null
}

function handleError(xhr, status, err) {
    if (status != 'abort') {
        console.log(xhr)
        console.log("status: " + status)
        console.log("error: " + err)
    }
    this.typeahead.addClass('closed')
}

module.exports = Typeahead