'use strict';

$(document).ready(function () {
    var $page = $('.js-table-page')
    if ($page.length === 0) return

    var column_settings = $page.data('table-columns')

    //add custom handlers
    for (var i = 0; i < column_settings.length; i++) {
        if (column_settings[i].sorter == "date") {
            column_settings[i].sorter = dateSorter
        }
        if (column_settings[i].formatter == "link") {
            column_settings[i].formatter = linkFormatter
        }
        if (column_settings[i].field == "amount") {
            column_settings[i].sorter = moneySorter
        }
    }
    var tabledata = $page.data('table-rows')
    var rowsToShow = 10;

    var input = null
    var inputs = $page.find('.js-table-input')    
    if (inputs && inputs.length > 0) {
        input = $(inputs[0])
        input.on('keyup', handleKeyup)
    }
    var searchBtn = null
    var searchBtns = $page.find('.js-table-button')
    if (searchBtns && searchBtns.length > 0) {
        $(searchBtns[0]).on('click', executeSearch)        
    }    

    var Tabulator = require('tabulator-tables')
    var table = new Tabulator('#table', {
        height: "100%",
        data: tabledata,
        layout: "fitColumns",
        pagination: "local",
        paginationSize: rowsToShow,
        pageLoaded: pageIsLoaded,
        columns: column_settings        
    })

    function pageIsLoaded(pageno) {
        if (pageno > 1) {
            var scrollPos = $("table").offset().top;
            $("body, html").animate({ scrollTop: scrollPos }, 400);
        }
    }

    function handleKeyup(e) {
        if (e.keyCode == 13) {
            executeSearch()
        }        
    }

    function executeSearch() {
        if (input && input.val().length > 1) {            
            table.setFilter(keywordFilter, input.val())
        } else {
            table.clearFilter()
        }
    }

    function keywordFilter(data, keyword) {        
        var keywords = keyword.toLowerCase().split(" ")        
        var value = ""
        for (var property in data) {
            value += data[property] ? String(data[property].toLowerCase()) : ""            
        }
        var matches = []
        keywords.forEach(function (keyword) {
            if (value.includes(keyword)) {
                matches.push(true)
            }
        })
        return matches.length === keywords.length

        // for (var property in data) {
        //     if (data[property] && data[property].toLowerCase().indexOf(keyword) > -1) {
        //          return true
        //     }
        // }        
        // return false
    }

    function dateSorter(a, b, aRow, bRow, column, dir, sorterParams) {        
        var date_a = new Date(a)
        var date_b = new Date(b)
        return hasValidDates(date_a, date_b) 
            ? date_a - date_b : 0        
    }

    function moneySorter(a, b, aRow, bRow, column, dir, sorterParams) {
        var number_a = formatNumber(a)
        var number_b = formatNumber(b)
        return number_a - number_b
    }

    function formatNumber(number){
        number = number.replace('$','')
        number = number.replace('#','')
        number = number.replace(/,/g, '')

        number = parseInt(number)
        return number
    }

    function hasValidDates(a, b) {
        return isValidDate(a) && isValidDate(b)
    }

    function isValidDate(date) {        
        return date instanceof Date && !isNaN(date)        
    }

    function linkFormatter(cell, formatterParams, onRendered) {        
        if (cell.getValue()) {            
            return "<a href='" + cell.getValue() + "' target='" + formatterParams.target + "'>" + formatterParams.label + "</a>"
        }
        return ''
    }
})