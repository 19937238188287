(function ($) {
    'use strict';

    $(document).ready(function bindCareersVideoHandlers() {

        // Allows content authors to add responsive YouTube embeds without modifications of the original snippet
        var $videoEmbedFrame = $('body .careers-landing-rich-text iframe[src^="https://www.youtube.com"]');

        if ($videoEmbedFrame && $videoEmbedFrame.length) {
            $videoEmbedFrame.wrap('<div class="responsive-video"></div>')
        }
    });
})($);