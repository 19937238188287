(function ($, window) {
    'use strict';

    //On Ready
    $(function () {
        var $modals = $('.js-modal');

        //Do we have this element on the page?? If so lets fire some events
        if ($modals.length > 0) {

            //find all elements 
            var $shadowBg = $('window-shade'),
                $body = $('body'),
                $activeModal,
                $modalWrapper,
                $modalShadow,
                $modalBtn;

            modalInit();
        }

        //Accessibility: Make js-modal accessible by keyboard-only users
        document.querySelectorAll('div[role="button"]').forEach(el => {
            el.addEventListener('keydown', e => {
                const keyDown = e.key !== undefined ? e.key : e.keyCode;
                if ( (keyDown === 'Enter' || keyDown === 13) || (['Spacebar', ' '].indexOf(keyDown) >= 0 || keyDown === 32)) {
                    // (prevent default so the page doesn't scroll when pressing space)
                    e.preventDefault();
                    el.click();
                }
            });
        });

        function activateModalContent($modalContent, widen) {
            $activeModal = $modalContent;
            
            $body.addClass('is-active-modal');

            if (!!$activeModal.data('video-highlight')) {
                $modalWrapper.addClass('video-modal-wrapper');
                $modalWrapper.removeClass('modal-wrapper');
            }

            if (widen) {
                $modalWrapper.addClass('modal-wrapper-wide');
            }

            $activeModal.appendTo($modalWrapper);
            
        }

        function modalEventHandler($modalTrigger) {
            $modalTrigger.on('click.modal', function (ev) {
                ev.preventDefault();

                var widenModal = ($modalTrigger.data('widen-modal') || 'False').toLowerCase() == 'true';
                
                var $modalContent = $modalTrigger.find('.js-modal-content').clone();

                activateModalContent($modalContent, widenModal);
                
                //Accessibility: Trap focus inside modal
                const modal = $('.js-modal-wrapper');
                const focusableElements = modal.find("a", $modalBtn);
                const firstFocusableEl = focusableElements[0];
                const lastFocusableEl = focusableElements[focusableElements.length-1]
                //Set focus on first element when modal opened
                setTimeout(() => {$modalBtn.focus();}, 200);
                //Set focus on button when last element blurred
                if(lastFocusableEl !== undefined){
                    lastFocusableEl.addEventListener('keydown', e => {
                        const keyDown = e.key !== undefined ? e.key : e.keyCode;
                        if ( (keyDown === 'Tab' || keyDown === 9) ) {
                            if(!e.shiftKey){
                                // (prevent default so the page doesn't scroll when pressing space)
                                e.preventDefault();
                                $modalBtn.focus();
                            }
                        }
                    })
                }
                //Loop focus to first element if applicable
                $modalBtn.on('keydown', e => {
                    const keyDown = e.key !== undefined ? e.key : e.keyCode;
                    if ( (keyDown === 'Tab' || keyDown === 9) ) {
                        // (prevent default so the page doesn't scroll when pressing space)
                      e.preventDefault();
                        if(e.shiftKey){
                            if(lastFocusableEl !== undefined){
                                lastFocusableEl.focus();
                            }
                        }
                        else if(firstFocusableEl !== undefined){
                            firstFocusableEl.focus();
                        }
                        else{
                            $modalBtn.focus();
                        }
                    }
                    else if(e.keyCode === 38 || e.keyCode === 40){
                        e.preventDefault();
                        return false;
                    }
                })
            });
        }      

        function modalClose() {

            $body.addClass('is-removing-modal');

            setTimeout(function removeModalContent() {
                $body.removeClass('is-active-modal is-removing-modal');
                var $videoModal = $('.video-modal-wrapper');
                if ($videoModal.length > 0) {
                    $videoModal.removeClass('video-modal-wrapper');
                    $videoModal.addClass('modal-wrapper');
                }
                $activeModal.remove();
            }, 600);
        }

        function modalInit() {

            //Setup DOM Elements
            if (!$modalShadow) {
                $modalShadow = $('<div class="modal-bg js-modal-bg">');
                $modalWrapper = $('<div class="modal-wrapper js-modal-wrapper" role="dialog" tabindex="0"></div>');
                $modalBtn = $('<button class="btn fa btn-modal-close" tabindex="0" aria-label="Close">&times;</button>');

                $modalShadow
                    .on('click.modalBackground', function (ev) {
                        modalClose();
                    })
                    .appendTo($body);

                $body.append($modalWrapper);

                $modalWrapper.on('keydown', e => {
                    const keyDown = e.key !== undefined ? e.key : e.keyCode;
                    if ( (keyDown === 'Escape' || keyDown === 27) ) {
                      // (prevent default so the page doesn't scroll when pressing space)
                      e.preventDefault();
                      modalClose();
                      $('.js-modal').focus();
                    }
                })

                $modalBtn
                    .attr('role', 'button')
                    .on('click.modalButton', function (ev) {
                        modalClose();
                        setTimeout(function(){
                            $('.js-modal').focus();
                        }, 200)
                    })
                    .on('keydown', e => {
                        const keyDown = e.key !== undefined ? e.key : e.keyCode;
                        if (e.keyCode === 38 || e.keyCode === 40){
                            e.preventDefault();
                            return false;
                        }
                    })
                    .appendTo($modalWrapper)

            }

            $modals.each(function (index, el) {
                var $modalTrigger = $(el);

                modalEventHandler($modalTrigger);
            });
        }
    });
})(jQuery, window);