(function($){
'use strict';

	$(document).ready(function bindCaseShowcaseHandlers(){

		var pageHasCaseShowcase,
			$caseShowcase,
			$readmoreBtn,
			$readlessBtn,
			$readmore;

		$caseShowcase = $('.case-showcase');

		pageHasCaseShowcase = $caseShowcase !== undefined;

		if (pageHasCaseShowcase) {
			$readmoreBtn = $caseShowcase.find('.btn-read-more');
			$readlessBtn = $caseShowcase.find('.btn-read-less');
			$readmore = $caseShowcase.find('.case-details-readmore');

			$readmoreBtn.click(handleReadmoreClick);
			$readlessBtn.click(handleReadlessClick);
		}

		function handleReadmoreClick(){
			$readmoreBtn.hide();
			$readmore.slideDown();
		}

		function handleReadlessClick(){
			$readmoreBtn.show();
			$readmore.slideUp();
		}

	});
})($);
