"use strict";

ko.bindingHandlers.executeOnEnter = {
  init: function(
    element,
    valueAccessor,
    allBindings,
    DONOTUSE,
    bindingContext
  ) {
    var viewModel = bindingContext.$root;
    $(element).on("keyup", function(event) {
      var value = ko.utils.unwrapObservable(valueAccessor());
      if (!!viewModel.SectionSearch) {
        viewModel.SectionSearch.KeywordFilter($(element).val());
      } else {
        viewModel.query.keyword($(element).val());
        viewModel.query.start(1);
        viewModel.query.scroll(0);
      }
      if (event.keyCode === 13) {
        //Accessibility: announce search results updated with change
        if($('#searchresultsupdated').text() == "Results Updated"){
          $('#searchresultsupdated').text("Result Updated");
        }
        else{
          $('#searchresultsupdated').text("Results Updated");
        }
        setTimeout(function() {$(makeKeyboardAccessible($('.fa.fa-times-circle')))}, 1000)
        if ($(".typeahead li.selected").length == 0) {
          value.call(viewModel);
          return false;
        }
      }
      return true;
    });
    setTimeout(function() {$(makeKeyboardAccessible($('.fa.fa-times-circle')))}, 1000)
  }
};

ko.bindingHandlers.filterableFilters = {
  init: function(
    element,
    valueAccessor,
    allBindings,
    DONOTUSE,
    bindingContext
  ) {
    var filters = ko.utils.unwrapObservable(valueAccessor());
    filters.map(function(filter, index) {
      var isVisible = filter.ShowOnEmpty();

      filter.ChildFilters().forEach(function(childFilter) {
        childFilter.IsVisible(childFilter.ShowOnEmpty());
        //show parent filter if child is visible
        if (!isVisible && childIsVisible) {
          isVisible = true;
          filter.IsExpanded(true);
        }
      });

      filter.IsVisible(isVisible);
    });
  },

  update: function(
    element,
    valueAccessor,
    allBindings,
    DONOTUSE,
    bindingContext
  ) {
    var viewModel = bindingContext.$root;
    var filters = ko.utils.unwrapObservable(valueAccessor());
    var input = $(element);
    var $numFilterResults = $('#numFilterResults');
    input.on("keyup", function(e) {
      var val = input.val().toLowerCase();
      if (!!val && val !== "") {
        var numVisible = 0;
        filters.forEach(function(filter) {
          var isVisible =
            filter.IsSelected() ||
            filter
              .Name()
              .toLowerCase()
              .indexOf(val) >= 0;
          if(isVisible){
            numVisible += 1;
          }
          filter.ChildFilters().forEach(function(childFilter) {
            var childIsVisible =
              childFilter.IsSelected() ||
              childFilter
                .Name()
                .toLowerCase()
                .indexOf(val) >= 0;
            childFilter.IsVisible(childIsVisible);
            //show parent filter if child is visible
            if (!isVisible && childIsVisible) {
              isVisible = true;
              filter.IsExpanded(true);
            }
          });
          filter.IsVisible(isVisible);
        });
        $numFilterResults.text(numVisible + ' results returned');
      } else {
        filters.map(function(filter, index) {
          //filter.IsVisible(filter.IsSelected() || index < viewModel.SectionSearch.FilterSection.MaxFilterGroupSize())
          filter.IsVisible(filter.ShowOnEmpty());
          filter.IsExpanded(false);
        });
      }
    });
  }
};

ko.bindingHandlers.singleSelectFilterClick = {
  init: function(
    element,
    valueAccessor,
    allBindings,
    DONOTUSE,
    bindingContext
  ) {
    var filter = bindingContext.$data;
    var search = ko.utils.unwrapObservable(valueAccessor());
    var viewModel = bindingContext.$root;
    var filterGroup = bindingContext.$parent;
    $(element).click(function(e) {
      if (!!viewModel.SectionSearch) {
        if (filter.IsSelected()) {
          filter.IsSelected(false);
        } else {
          filterGroup.clearFilterGroup();
          filter.IsSelected(true);
          setTimeout(function() {$(makeKeyboardAccessible($('.fa.fa-times-circle')))}, 1000)
        }
      } else {
        var prevIsSelected = filter.isSelected();
        viewModel.clearFilters();
        filter.isSelected(!prevIsSelected);
      }
      setTimeout(function() {$(makeKeyboardAccessible($('.fa.fa-times-circle')))}, 1000)
      search.call(viewModel);
      return true;
    });
  }
};

ko.bindingHandlers.dateFilterClick = {
  update: function(
    element,
    valueAccessor,
    allBindings,
    DONOTUSE,
    bindingContext
  ) {
    var filter = bindingContext.$data;
    var search = ko.utils.unwrapObservable(valueAccessor());
    var viewModel = bindingContext.$root;
    var filterGroup = viewModel.SectionSearch.FilterSection.DateFilterGroup;
    $(element).click(function(e) {
      e.preventDefault();
      e.stopPropagation();
      var isSelected = filter.IsSelected();
      if (filter.IsSelected()) {
        filter.IsSelected(false);
      } else if (filter.IsCustom()) {
        filterGroup.clearFilterGroup();
        filter.IsSelected(true);
      } else if (filterGroup.AllowMultiSelect()) {
        var customFilter = filterGroup.Filters().filter(function(x) {
          return x.IsCustom();
        });
        if (customFilter.length > 0) {
          customFilter[0].IsSelected(false);
        }
        filter.IsSelected(true);
      } else {
        filterGroup.clearFilterGroup();
        filter.IsSelected(true);
      }
      if (!filter.IsCustom()) {
        search.call(viewModel);
      }
    });

    if (filter.IsCustom()) {
        if (filter.IsGoogleSearchInput()) {

            $("#googleDatepickerFrom").change(function(e){
                filter.From(Date.parse(e.target.value))
                console.log("From")
                if (!!filter.From() && !!filter.To()) {
                    search.call(viewModel);
                  }
            })
            $("#googleDatepickerTo").change(function(e){
                console.log("To")
                filter.To(Date.parse(e.target.value))
                if (!!filter.From() && !!filter.To()) {
                    search.call(viewModel);
                  }
            })
      } else {
        var datePickerConfig = {
          defaultDate: null,
          onSelect: function(dateString, picker) {
            if ($(this).hasClass("from")) {
              filter.From(Date.parse(dateString));
            } else if ($(this).hasClass("to")) {
              filter.To(Date.parse(dateString));
            }
            if (!!filter.From() && !!filter.To()) {
              search.call(viewModel);
            }
          }
        };
        $(".datepicker").datepicker(datePickerConfig);
        if (filter.From() > -62135575200000 && !!filter.From()) {
          $(".datepicker.from").datepicker("setDate", new Date(filter.From()));
        } else {
          filter.From(null);
        }
        if (filter.To() < 253402300799999 && !!filter.To()) {
          $(".datepicker.to").datepicker("setDate", new Date(filter.To()));
        } else {
          filter.To(null);
        }
      }
    }
  }
};
ko.bindingHandlers.dateFilterRecoveryClick = {
  update: function(
    element,
    valueAccessor,
    allBindings,
    DONOTUSE,
    bindingContext
  ) {
    var filter = bindingContext.$data;
    var search = ko.utils.unwrapObservable(valueAccessor());
    var viewModel = bindingContext.$root;
    $(element).click(function(e) {
      e.preventDefault();
      e.stopPropagation();
      if (filter.IsSelected()) {
        filter.IsSelected(false);
      } else {
        filter.IsSelected(true);
      }
      if (!filter.IsCustom()) {
        search.call(viewModel);
      }
    });
  }
};
ko.bindingHandlers.multiSelectFilterClick = {
  update: function(
    element,
    valueAccessor,
    allBindings,
    DONOTUSE,
    bindingContext
  ) {
    var filter = bindingContext.$data;
    var search = ko.utils.unwrapObservable(valueAccessor());
    var viewModel = bindingContext.$root;
    $(element).click(function(e) {
      // Allow Click To Flow Thru To checked binding of input element
      if (!!viewModel.SectionSearch) {
        if (filter.IsEnabled()) {
          search.call(viewModel);
        }
      } else {
        //sitesearch
        viewModel.query.start(1);
        viewModel.query.scroll(0);
        search.call(viewModel);
      }
      setTimeout(function() {$(makeKeyboardAccessible($('.fa.fa-times-circle')))}, 1000)
      return true;
    });
  }
};

ko.bindingHandlers.clearFilterClick = {
  update: function(
    element,
    valueAccessor,
    allBindings,
    DONOTUSE,
    bindingContext
  ) {
    var filter = bindingContext.$data;
    var search = ko.utils.unwrapObservable(valueAccessor());
    var viewModel = bindingContext.$root;
    $(element).click(function(e) {
      e.preventDefault();
      e.stopPropagation();
      var isClearFilter = allBindings.get("isClearFilter");
      //Special logic for clearing all filters
      if (!!isClearFilter) {
      } else {
        filter.Clear()
      }
      search.call(viewModel);
      setTimeout(function() {
        $(makeKeyboardAccessible($('.fa.fa-times-circle')));
        if($('.fa.fa-times-circle')[0] !== undefined){
          $('.fa.fa-times-circle')[0].focus();
        }
        else if($('.article').find('a')[0] !== undefined){
          $('.article').find('a')[0].focus();
        }
        else{
          $('.search-box').find('input')[0].focus()
        }
      }, 500)
      return false;
    });
  }
};

ko.bindingHandlers.onSelectChanged = {
  update: function(
    element,
    valueAccessor,
    allBindings,
    DONOTUSE,
    bindingContext
  ) {
    var search = ko.utils.unwrapObservable(valueAccessor());
    var viewModel = bindingContext.$root;
    $(element).change(function(e) {
      if (!!viewModel.SectionSearch.IsDefaultSort) {
        viewModel.SectionSearch.IsDefaultSort(false);
      }
      search();
    });
  }
};

ko.bindingHandlers.singleFilter = {
  update: function(
    element,
    valueAccessor,
    allBindings,
    DONOTUSE,
    bindingContext
  ) {
    var viewModel = bindingContext.$root;
    var filter = ko.utils.unwrapObservable(valueAccessor());
    $(element).click(function(e) {
      e.preventDefault();
      e.stopPropagation();
      filter.IsSelected(!filter.IsSelected());
      viewModel.Search();
    });
  }
};

ko.bindingHandlers.foreachProp = {
  transformObject: function(obj) {
    var properties = _.map(obj, function(val, key) {
      return val;
    });
    return properties;
  },
  init: function(
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    var value = ko.utils.unwrapObservable(valueAccessor());
    var properties = ko.bindingHandlers.foreachProp.transformObject(value);
    ko.applyBindingsToNode(element, { foreach: properties }, bindingContext);
    return { controlsDescendantBindings: true };
  }
};

ko.virtualElements.allowedBindings.foreachProp = true;

//Accessibility: Make items on page accessible by keyboard-only users
function makeKeyboardAccessible (item) {
  item.each(function() {
    $(this).on('keydown', e => {
      const keyDown = e.key !== undefined ? e.key : e.keyCode;
      if ( (keyDown === 'Enter' || keyDown === 13) ) {
        // (prevent default so the page doesn't scroll when pressing space)
        e.preventDefault();
        $(this).click();
      }
    });
  }); 
}
