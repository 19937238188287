'use strict';

/* This handles counter and infographic animations */
function AnimateCounter($animateNode, contentOffsetTop, contentOffsetBottom) {
    this.$animateNode = $animateNode;
    this.contentOffsetTop = contentOffsetTop;
    this.contentOffsetBottom = contentOffsetBottom;
    this.fired = false;

    var viewableLogic = this.viewableLogic();
    viewableLogic(window.pageYOffset);
    windowListener.onScroll(viewableLogic);
}

AnimateCounter.prototype.viewableLogic = function () {
    var fired = this.fired;
    var contentOffsetTop = this.contentOffsetTop;
    var contentOffsetBottom = this.contentOffsetBottom;
    var $animatedNode = this.$animateNode;
    var fireCounter = this.fireCounter;

    return function (windowOffset) {
        if (!fired) {
            // Calculate the offset of the middle of the animate node.
            var nodeTop = $animatedNode.offset().top - windowOffset;
            var nodeMiddle = nodeTop + ($animatedNode.outerHeight() / 2);

            // Fire the counter when the middle of the node becomes viewable in the content area.
            if (nodeMiddle >= contentOffsetTop && nodeMiddle <= contentOffsetBottom) {
                fired = true;
                fireCounter($animatedNode);
            }
        }
    };
};


AnimateCounter.prototype.fireCounter = function ($animatedNode) {
    //Grab all counter items
    var $counterItem = $animatedNode.find('.js-animated-counter-item'),
        $infographicItems = $animatedNode.find('.js-animated-highlight-item');

    //For each counter item we will grab settings and animate the number corresponding to data attributes
    if ($counterItem.length > 0) {
        $counterItem.each(function () {

            var $currentCounterItem = $(this),
                $counterNode = $currentCounterItem.find('.js-animated-counter-text'),
                counterStartNumber = $counterNode.text() || 0,
                counterEndNumber = $currentCounterItem.data('target-count') || 100,
                counterDuration = $currentCounterItem.data('duration') || 2000;

            $({ Counter: counterStartNumber }).animate(
                { Counter: counterEndNumber },
                {
                    duration: counterDuration,
                    easing: 'swing',
                    step: function () {
                        $counterNode.text(Math.ceil(this.Counter));
                    }
                }

            );
        });
    }

    //we have more than one infographic
    if ($infographicItems.length > 0) {

        $infographicItems.each(function () {

            var $currentInfographic = $(this),
                $infoImage = $currentInfographic.find('.infographic-overlay'),
                counterStartNumber = $infoImage.height(),
                counterEndNumber = 0,
                counterDuration = $currentInfographic.data('duration') || 2000;

            $({ Counter: counterStartNumber }).animate(
                { Counter: counterEndNumber },
                {
                    duration: counterDuration,
                    easing: 'swing',
                    step: function () {

                        $infoImage.css('clip', 'rect(' + Math.floor(this.Counter) + 'px, auto, auto, 0)');
                    }
                }

            );
        });
    }
};


$(window).on('load', function () {
    var $animatedCounter = $('.js-animated-counter');

    //Do we have this element on the page?? If so lets fire some events
    if ($animatedCounter.length > 0) {
        var headerHeight = $('.site-header').outerHeight() || 0;
        var subNavHeight = $('.site-subnav').outerHeight() || 0;
        var anchorTabsHeight = $('.site-anchor-tabs').outerHeight() || 0;

        // Calculate the top and bottom offsets for the content area.
        var contentOffsetTop = headerHeight + subNavHeight;
        var contentOffsetBottom = window.innerHeight - anchorTabsHeight;

        $animatedCounter.each(function () {
            // Calculate the 
            var animateNode = new AnimateCounter($(this), contentOffsetTop, contentOffsetBottom);
        });
    }
});