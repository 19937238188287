'use strict'

$(function () {
    var $paginatedLists = $('.js-paginated-list')

    if ($paginatedLists) {

        $.each($paginatedLists, function (key, listing) {
            var $listing = $(listing)
            var $loadMoreButton = $listing.find('.btn-load-more')
            var $listItems = $listing.find('.js-paginated-list-item')
            var pageSize = $listing.data('page-size')
            var currentPage = 1
            var displayCount = function () {
                return (pageSize * currentPage)
            }

            function initializePaginatedList() {
                $.each($listItems.slice(0, displayCount()), function (i, elem) { $(elem).show() })
                $.each($listItems.slice(displayCount()), function (i, elem) { $(elem).hide() })
                setLoadMore()
            }

            $loadMoreButton.on('click', function () {
                $.each($listItems.slice(displayCount(), displayCount() + pageSize), function (i, elem) { $(elem).show() })
                currentPage++
                setLoadMore()
            })

            function setLoadMore() {
                if (displayCount() >= $listItems.length) {
                    $loadMoreButton.hide()
                }
            }

            initializePaginatedList()
        })        
    }
})