'use strict';
$(document).ready(function () {

    var $page = $('.js-service-landing')
    if ($page.length === 0) {
        return;
    }

    function scrollToTarget(id) {
        var el = $('#' + id)
        if (el.length > 0) {
            $('html, body').animate({
                scrollTop: el.offset().top - ($('.jump-links').outerHeight() + $('.site-subnav').outerHeight() + $('.site-header').outerHeight())
            }, 500)
        }
    }

    function checkSticky() {
        var $jumplinks = $('.jump-links')
        var $jumpLinksPosition = $('.jump-links__position')
        var windowTop = $(window).scrollTop() + ($('.site-header').outerHeight() + $('.site-subnav').outerHeight())
        var stickyTop = $jumpLinksPosition.offset().top
        if ($('.site-subnav').length) {
            $jumplinks.addClass('jump-links--padding')
        }
        if (windowTop > stickyTop) {
            $jumplinks.addClass('jump-links--fixed')
            $jumpLinksPosition.css('height', $jumplinks.outerHeight())
        } else {
            $jumplinks.removeClass('jump-links--fixed')
            $jumpLinksPosition.css('height', 0)
        }
    }

    var $jumpLinkButtons = $('.jump-link--js')
    $jumpLinkButtons.on('click', function () {
        $('.jump-links__link').removeClass('jump-links__link--active')
        $(this).addClass('jump-links__link--active')
        var id = $(this).data('key')
        scrollToTarget(id)
        $('#'+id).find('a').eq(0).focus()
    })

    $('.jump-links__select--js').on('change', function(){
        var id = this.value;
        scrollToTarget(id)
    })

    var isAzState = false
    var $servicesections = $('.js-service-section')
    $('.js-view-all').on('click', function () {
        isAzState = !isAzState

        //disable jump link buttons when viewing all
        $jumpLinkButtons.each(function (i, elem) {
            $(elem).removeClass('jump-links__link--active')
            $(elem).prop("disabled", isAzState)
        })        

        var $this = $(this)
        $this.text(isAzState ? $this.data('back-label') : $this.data('all-label'))
        $this.attr('aria-label', isAzState ? $this.data('back-label') : $this.data('all-label') )
        $servicesections.each(function (i, elem) {
            $(elem).toggleClass('service-landing__section--hidden')
        })
    })    

    var url = $page.attr('data-search-url')
    var keyword = ''
    $('.search-box__input').on('keyup', function (e) {
        e.preventDefault()
        e.stopPropagation()
        keyword = this.value
        if (e.keyCode == 13 && keyword.length > 0) {
            window.location.href = url + '?keyword=' + encodeURIComponent(keyword) + '&section=services'
        }
    })
    
    $('.search-box__button').click(function (e) {
        e.preventDefault()
        e.stopPropagation()        
        if (keyword.length > 0) {
            window.location.href = url + '?keyword=' + encodeURIComponent(keyword) + '&section=services'
        }
    })    

    $(window).on('scroll', checkSticky)

    checkSticky()
})