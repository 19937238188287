;(function ($) {
  'use strict'
  /// site search
  /// Mobile nav toggle
  $(document).ready(function () {
    var $searchToggle = $('.search-toggle')
    var $siteSearch = $('.site-search')
    var $searchInput = $siteSearch.find('input')
    var $mobileMenu = $('.header-nav-menu')
    var $body = $('body')

    $siteSearch.on('transitionend', function handleInputTransitionEnd(e) {
      e.stopPropagation()
      if ($(this).hasClass('active')) {
        var typeahead = $(this).children('.typeahead')
        if (typeahead.length > 0 && typeahead.hasClass('closed')) {
          $searchInput.focus()
        }
      } else {
        $searchInput.blur()
      }
    })

    $searchInput.on('keydown', e => {
      const keyDown = e.key !== undefined ? e.key : e.keyCode;
      if ( (keyDown === 'Tab' || keyDown === 9) ) {
        if(e.shiftKey){
          closeSearch();
          $searchToggle[0].focus()
        }
      }
    })

    function toggleSearch() {
      $searchToggle.toggleClass('active')
      $searchToggle.removeAttr("aria-expanded");
      $siteSearch.toggleClass('active')
      $mobileMenu.toggleClass('active')
      $fullscreen.toggle()
      closeNav()
    }

    function closeSearch() {
      $searchToggle.removeClass('active')
      $searchToggle.attr("aria-expanded", "false");
      $siteSearch.removeClass('active')
      $fullscreen.hide()
    }

    $searchToggle.click(toggleSearch)
    //Accessibility: close search modal when not in focus 
    $(".featured-navitem").focus(closeSearch)

    /// Mobile nav toggle
    var $mobileToggle = $('.mobile-nav-toggle'),
      $siteHeader = $mobileToggle.parent(),
      $nav = $('.header-nav-menu'),
      $mobileMenuIcon = $('button.tcon'),
      iconActiveClass = 'tcon-transform',
      $fullscreen = $('.fullscreen-dim')

    function toggleNav() {
      closeSearch()
      // $fullscreen.toggle()
      $mobileMenuIcon.toggleClass(iconActiveClass)
      $siteHeader.toggleClass('is-mobilenav-active')
      $body.toggleClass('is-mobilenav-active')
      $nav.toggleClass('active')
    }

    function closeNav() {
      // $fullscreen.hide();
      $siteHeader.removeClass('is-mobilenav-active')
      $body.removeClass('is-mobilenav-active')
      $nav.removeClass('active')
      $mobileMenuIcon.removeClass(iconActiveClass)
      $('.featured-navitem-search').find('.search-toggle').attr("aria-expanded", "false")
      $('.featured-navitem-search').find('.search-toggle').focus()
    }

    $fullscreen.click(closeSearch)

    $mobileToggle.click(toggleNav)

    function simpleToggle($trigger) {
      var $this = $trigger ? $trigger : $(this),
        $toggleContent = $this.next()

      $this.toggleClass('is-active-toggle')
      $toggleContent.slideToggle().toggleClass('is-active-toggle-content')
    }

    //simple Toggle
    var $toggleTriggers = $('.js-simple-toggle')

    //if they exist on the page fire the even handler
    if ($toggleTriggers.length) {
      $toggleTriggers.on('click.simpleToggle', function (ev) {
        ev.preventDefault()
        simpleToggle.call(this)
      })
    }

    var $footerLinksExpander = $('.js-footer-links-expander')
    var $footerLinks = $('.js-footer-links')
    $footerLinksExpander.on('click', function () {
      if ($footerLinks) {
        $footerLinks.slideToggle()
        // $footerLinks.toggleClass('footer-links__legal-policy--collapsed')
        $footerLinksExpander.toggleClass('footer-links__expander--expanded')
        $footerLinksExpander.attr("aria-expanded", (_, attr) => attr == "true" ? "false" : "true");
        var scroll = $footerLinks.offset().top
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: scroll
          },
          400
        )
      }
    })
  })
})(jQuery)

// http://paulirish.com/2011/requestanimationframe-for-smart-animating/
// http://my.opera.com/emoller/blog/2011/12/20/requestanimationframe-for-smart-er-animating
// requestAnimationFrame polyfill by Erik Möller
// fixes from Paul Irish and Tino Zijdel
;(function () {
  'use strict'

  var lastTime = 0
  var vendors = ['ms', 'moz', 'webkit', 'o']
  for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame']
    window.cancelAnimationFrame =
      window[vendors[x] + 'CancelAnimationFrame'] ||
      window[vendors[x] + 'CancelRequestAnimationFrame']
  }

  if (!window.requestAnimationFrame)
    window.requestAnimationFrame = function (callback, element) {
      var currTime = new Date().getTime()
      var timeToCall = Math.max(0, 16 - (currTime - lastTime))
      var id = window.setTimeout(function () {
        callback(currTime + timeToCall)
      }, timeToCall)
      lastTime = currTime + timeToCall
      return id
    }

  if (!window.cancelAnimationFrame)
    window.cancelAnimationFrame = function (id) {
      clearTimeout(id)
    }
})()

/*  
    jQuery pub/sub plugin by Peter Higgins (dante@dojotoolkit.org)
    Loosely based on Dojo publish/subscribe API, limited in scope. Rewritten blindly.
    Original is (c) Dojo Foundation 2004-2010. Released under either AFL or new BSD, see:
    http://dojofoundation.org/license for more information.
*/

;(function (d) {
  // the topic/subscription hash
  var cache = {}

  d.publish = function (/* String */ topic, /* Array? */ args) {
    // summary:
    //      Publish some data on a named topic.
    // topic: String
    //      The channel to publish on
    // args: Array?
    //      The data to publish. Each array item is converted into an ordered
    //      arguments on the subscribed functions.
    //
    // example:
    //      Publish stuff on '/some/topic'. Anything subscribed will be called
    //      with a function signature like: function(a,b,c){ ... }
    //
    //  |       $.publish("/some/topic", ["a","b","c"]);
    cache[topic] &&
      d.each(cache[topic], function () {
        this.apply(d, args || [])
      })
  }

  d.subscribe = function (/* String */ topic, /* Function */ callback) {
    // summary:
    //      Register a callback on a named topic.
    // topic: String
    //      The channel to subscribe to
    // callback: Function
    //      The handler event. Anytime something is $.publish'ed on a
    //      subscribed channel, the callback will be called with the
    //      published array as ordered arguments.
    //
    // returns: Array
    //      A handle which can be used to unsubscribe this particular subscription.
    //
    // example:
    //  |   $.subscribe("/some/topic", function(a, b, c){ /* handle data */ });
    //
    if (!cache[topic]) {
      cache[topic] = []
    }
    cache[topic].push(callback)
    return [topic, callback] // Array
  }

  d.unsubscribe = function (/* Array */ handle) {
    // summary:
    //      Disconnect a subscribed function for a topic.
    // handle: Array
    //      The return value from a $.subscribe call.
    // example:
    //  |   var handle = $.subscribe("/something", function(){});
    //  |   $.unsubscribe(handle);

    var t = handle[0]
    cache[t] &&
      d.each(cache[t], function (idx) {
        if (this == handle[1]) {
          cache[t].splice(idx, 1)
        }
      })
  }
})(jQuery)
